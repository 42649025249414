import {useState} from "react";
import {Records} from "./Records";
import CreateChargeModal from "./CreateChargeModal";
import {markAsPaid} from "./actions";

import {useToast} from "@chakra-ui/react";
export default function PatientPayment({
  appointment,
  state,
  socket,
  dispatch,
  setInvOrRecExist,
  invOrRecExist,
}) {
  const [chargeModal, setChargeModal] = useState(false);
  const toast = useToast();
  let otbp =
    parseFloat(appointment?.patientAmount ?? 0) -
    parseFloat(appointment?.amountPaidByPatient ?? 0);

  return (
    <div className="flex-1 flex flex-col px-5 p-2  rounded-lg  bg-[#BBBBFF]">
      <div>
        <p className="text-[#FFF] text-sm mt-3">
          patient &nbsp;
          <span
            style={{
              backgroundColor:
                !(otbp > 0) || appointment?.paymentStatus === "paid"
                  ? "#03A700"
                  : "#FF3636",
            }}
            className=" text-xs  rounded-full text-white p-[3px] px-2"
          >
            {`${
              parseFloat(appointment?.patientAmount ?? 0) === 0
                ? "no amount"
                : otbp > 0
                ? "payment pending"
                : `${appointment.paymentMethod || ""}`
            }`}
          </span>
        </p>
      </div>

      <div className="w-full flex justify-between space-x-4 mt-5">
        <button
          onClick={(e) => {
            if (otbp > 0 && appointment?.paymentStatus !== "paid") {
              setChargeModal(true);
            }
          }}
          disabled={!(otbp > 0) || appointment?.paymentStatus === "paid"}
          className={`focus:outline-none  bg-[#827FFF] text-[#FFF] disabled:cursor-not-allowed flex justify-center items-center rounded-md p-2  w-full whitespace-nowrap`}
        >
          <span className="font-medium text-sm">Bill via CC</span>
        </button>

        <button
          onClick={(e) => {
            if (otbp > 0 && appointment?.paymentStatus !== "paid") {
              markAsPaid(appointment, state, dispatch, socket, toast);
            }
          }}
          disabled={!(otbp > 0) || appointment?.paymentStatus === "paid"}
          className={`focus:outline-none  text-[#827FFF] disabled:cursor-not-allowed flex justify-center items-center bg-transparent ring-[1px] active:ring-0 ring-[#827FFF]  rounded-md p-2  w-full whitespace-nowrap`}
        >
          <span className="font-medium text-sm">Mark as paid</span>
        </button>
      </div>
      {appointment && (
        <Records
          appointment={appointment}
          type="patient"
          state={state}
          setInvOrRecExist={setInvOrRecExist}
          invOrRecExist={invOrRecExist}
        />
      )}

      <div className="flex justify-center items-center w-full my-3">
        <div className="bg-[#5754FF] p-2 rounded-xl flex min-w-[10rem] flex-col justify-center items-center">
          <p className="text-white text-[11px]">
            {" "}
            <span className="">{"outstanding balance: "}</span>
            <span className="font-semibold text-xs">
              {`$${(
                (appointment?.noShowFee ? appointment.amount : (appointment?.patientAmount ?? appointment?.amount)) -
                (appointment?.amountPaidByPatient || 0)
              ).toFixed(2)}`}
            </span>
          </p>
          <p className="text-white text-[11px]">
            {" "}
            <span className="">{"amount paid: "}</span>
            <span className="font-semibold text-xs">
              {`$${parseFloat(appointment?.amountPaidByPatient ?? 0).toFixed(
                2
              )}`}
            </span>
          </p>
        </div>
      </div>

      {chargeModal && appointment && !appointment?.cancelled && (
        <CreateChargeModal
          state={state}
          appointment={appointment}
          stateChanger={setChargeModal}
          dispatch={dispatch}
          socket={socket}
        />
      )}
    </div>
  );
}
