import "react-querybuilder/dist/query-builder-layout.css";
import "./styles.css";
import React, {useState} from "react";
import {
  Box,
  FormControl,
  RadioGroup,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Radio,
  Stack,
  Spacer,
  VStack,
  Accordion,
  Flex,
  HStack,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Select,
  Input,
  AlertDialog,
  AlertDialogBody,
  CloseButton,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Checkbox,
  Text,
} from "@chakra-ui/react";
import {
  SettingsIcon,
  EditIcon,
  CopyIcon,
  DeleteIcon,
  CheckIcon,
  AddIcon,
} from "@chakra-ui/icons";
import {Field} from "formik";
import {QueryBuilderChakra} from "@react-querybuilder/chakra";
import {QueryBuilder} from "react-querybuilder";
import {ExportToExcelButton} from "./ExportToExcelButton";
import {ChakraProvider, extendTheme} from "@chakra-ui/react";

const chakraTheme = extendTheme();

const ReportFieldCheckboxGroups = ({
  fields,
  source,
  maxItemsPerColumn = 10,
}) => {
  const splitIntoColumns = (fields, maxItemsPerColumn) => {
    const numberOfColumns = Math.ceil(fields.length / maxItemsPerColumn);
    const columns = Array.from({length: numberOfColumns}, (_, colIndex) => {
      return fields.slice(
        colIndex * maxItemsPerColumn,
        (colIndex + 1) * maxItemsPerColumn
      );
    });
    return columns;
  };

  const fieldColumns = splitIntoColumns(fields, maxItemsPerColumn);

  return (
    <HStack align="start" spacing={4}>
      {fieldColumns.map((column, colIndex) => (
        <VStack key={colIndex} align="start">
          {column.map((field) => (
            <Field key={field.name} name={`${source}.${field.name}`}>
              {({field: {value, ...rest}}) => (
                <Checkbox
                  {...rest}
                  isChecked={value ?? field.default} // Use the default value if value is undefined or null
                >
                  {field.label}
                </Checkbox>
              )}
            </Field>
          ))}
        </VStack>
      ))}
    </HStack>
  );
};
const ReportFieldRadioGroups = ({
  fields,
  maxItemsPerColumn = 10,
  selectedValue,
  setSelectedValue,
}) => {
  const splitIntoColumns = (fields, maxItemsPerColumn) => {
    const numberOfColumns = Math.ceil(fields.length / maxItemsPerColumn);
    const columns = Array.from({length: numberOfColumns}, () => []);
    fields.forEach((field, index) => {
      columns[index % numberOfColumns].push(field);
    });
    return columns;
  };

  const fieldColumns = splitIntoColumns(fields, maxItemsPerColumn);

  const handleChange = (value) => {
    setSelectedValue(value);
  };

  return (
    <Flex wrap="wrap" justify="start" width="100%">
      {fieldColumns.map((column, colIndex) => (
        <Box
          key={colIndex}
          width={`${100 / fieldColumns.length}%`}
          minWidth="200px"
          pr={4}
        >
          <RadioGroup onChange={handleChange} value={selectedValue}>
            <VStack align="start" spacing={2}>
              {column.map((field) => (
                <Radio key={field.name} value={field.name}>
                  {field.label}
                </Radio>
              ))}
            </VStack>
          </RadioGroup>
        </Box>
      ))}
    </Flex>
  );
};
const SavedReportsDropdown = ({
  savedReports,
  onSelectSavedReports,
  selectedSavedReport,
  customReportName,
  handleChange,
  selectedCollection,
  onSaveReport,
  formikProps,
  onDeleteReport,
}) => {
  const [newReportName, setNewReportName] = useState("");
  return (
    <Box display="flex" alignItems="flex-end" width="full" paddingTop="2">
      <FormControl id="selectedSavedReport">
        <HStack>
          <Flex align={"center"} gap={"2"} width="100%">
            <Text
              mr="1"
              paddingLeft="2"
              fontWeight="semibold"
              color="blue.600"
              fontSize="15px"
              whiteSpace={"nowrap"}
            >
              Custom Queries
            </Text>
            <Select
              placeholder="-"
              onChange={onSelectSavedReports}
              value={selectedSavedReport || ""}
              mr={1}
              width="full"
              size="sm"
              rounded={"md"}
            >
              {savedReports
                .filter((reportType) => {
                  return reportType.collectionName === selectedCollection;
                })
                .map((selection) => (
                  <option key={selection.reportid} value={selection.reportid}>
                    {selection.name}
                  </option>
                ))}
            </Select>
          </Flex>
          {selectedSavedReport && (
            <DeleteReportDialog
              onDeleteReport={onDeleteReport}
              formikProps={formikProps}
            />
          )}

          <Popover>
            <PopoverTrigger>
              <Button
                colorScheme="blue"
                variant="outline"
                size="sm"
                rounded={"md"}
              >
                New Query
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader color="blue.500">New query</PopoverHeader>
              <PopoverBody>
                <Box display="flex">
                  <Text
                    mr="1"
                    paddingLeft="2"
                    paddingTop="1"
                    fontWeight="semibold"
                    color="blue.600"
                    fontSize="15px"
                    whiteSpace={"nowrap"}
                  >
                    Query name
                  </Text>
                  <Input
                    placeholder="New report title"
                    value={newReportName || ""}
                    onChange={(evt) => setNewReportName(evt.target.value)}
                    width="full"
                    size="sm"
                    rounded={"md"}
                  />
                </Box>
              </PopoverBody>
              <Button
                onClick={() => onSaveReport(formikProps, false, newReportName)}
                colorScheme="blue"
                variant="outline"
                size="sm"
                rounded={"md"}
                isDisabled={!newReportName?.length}
              >
                Save
              </Button>
            </PopoverContent>
          </Popover>
        </HStack>
      </FormControl>
    </Box>
  );
};

const DisplayedColumnsModal = ({collection}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const maxItemsPerColumn = 10;
  const getModalSize = (fieldCount) => {
    if (fieldCount <= maxItemsPerColumn) return "md";
    if (fieldCount <= 2 * maxItemsPerColumn) return "xl";
    if (fieldCount <= 3 * maxItemsPerColumn) return "2xl";
    if (fieldCount <= 4 * maxItemsPerColumn) return "4xl";
    return "4xl";
  };
  const modalSize = getModalSize(collection.fields.length);
  return (
    <Box marginY="4">
      <Button colorScheme="blue" onClick={onOpen}>
        Add/Remove Fields
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size={modalSize}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Currently active columns</ModalHeader>
          <ModalBody>
            <ReportFieldCheckboxGroups
              fields={collection.fields}
              source="activeColumns"
              maxItemsPerColumn={10}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
const GroupFieldsModal = ({collection, formikProps, queryData}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [selectedValue, setSelectedValue] = useState("");

  const maxItemsPerColumn = 10;
  const getModalSize = (fieldCount) => {
    if (fieldCount <= maxItemsPerColumn) return "md";
    if (fieldCount <= 2 * maxItemsPerColumn) return "xl";
    if (fieldCount <= 3 * maxItemsPerColumn) return "2xl";
    if (fieldCount <= 4 * maxItemsPerColumn) return "4xl";
    return "4xl";
  };
  const modalSize = getModalSize(collection.fields.length);
  return (
    <Box marginY="4">
      <Button
        colorScheme="blue"
        variant="outline"
        size="sm"
        rounded={"md"}
        onClick={onOpen}
      >
        Choose Field
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size={modalSize}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Group by</ModalHeader>
          <ModalBody>
            <ReportFieldRadioGroups
              fields={collection.fields}
              source="groupBy"
              maxItemsPerColumn={10}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} marginX="2">
              Go Back
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                const updatedGroupBy = [
                  ...(formikProps.values.groupBy || []),
                  selectedValue,
                ];
                formikProps.setValues({
                  ...formikProps.values,
                  groupBy: updatedGroupBy,
                });
                queryData(
                  formikProps.values.collection,
                  formikProps.values.query,
                  updatedGroupBy,
                  formikProps
                );
                onClose();
              }}
            >
              Apply
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
const DeleteReportDialog = ({onDeleteReport, formikProps}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const cancelRef = React.useRef();

  return (
    <>
      <Button
        colorScheme="red"
        onClick={onOpen}
        variant="outline"
        m="1.5"
        px="6"
        size="sm"
        ml="1"
        leftIcon={<DeleteIcon />}
      >
        Delete
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Report
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this report? This action cannot be
              undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => onDeleteReport(formikProps)}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const operators = [
  {name: "=", value: "=", label: "="},
  {name: "!=", value: "!=", label: "!="},
  {name: "<", value: "<", label: "<"},
  {name: ">", value: ">", label: ">"},
  {name: "<=", value: "<=", label: "<="},
  {name: ">=", value: ">=", label: ">="},
  {name: "contains", value: "contains", label: "contains"},
  {name: "doesNotContain", value: "doesNotContain", label: "does not contain"},
  {name: "null", value: "null", label: "is null"},
  {name: "notNull", value: "notNull", label: "is not null"},
  {name: "between", value: "between", label: "between"},
  {name: "notBetween", value: "notBetween", label: "not between"},
];

const DeleteItemButton = ({handleOnClick, ...props}) => (
  <Button
    type="button"
    onClick={handleOnClick}
    colorScheme="red"
    variant="solid"
    m="1.5"
    size="sm"
    ml="1"
    {...props}
  >
    <DeleteIcon />
  </Button>
);

export const DynamicReportFilters = ({
  collections,
  onCollectionChange,
  savedReports,
  selectedSavedReport,
  customReportName,
  onSelectSavedReports,
  onSaveReport,
  formikProps,
  onUpdateReport,
  onDeleteReport,
  queryData,
  collection,
  resultingData,
}) => {
  const fields = collection.fields.map((field) => {
    const editorTypes = [
      "text",
      "checkbox",
      "select",
      "radio",
      "textarea",
      "multiselect",
    ];
    const inputTypes = ["date", "time", "datetime-local"];

    if (editorTypes.includes(field.type)) {
      const newField = {
        name: field.name,
        label: field.label,
        valueEditorType: field.type,
      };

      if (field.type === "radio" && field.options) {
        newField.values = field.options.map((option) => ({
          label: option,
          value: option,
          name: option,
        }));
        newField.valueSources = ["value"];
        newField.valueEditorType = "select";
      }

      return newField;
    } else if (inputTypes.includes(field.type)) {
      return {
        name: field.name,
        label: field.label,
        inputType: field.type,
      };
    } else {
      return {
        name: field.name,
        label: field.label,
      };
    }
  });
  return (
    <Box width="full">
      <FormControl id="collection">
        <Stack direction="row" spacing={4}>
          {collections.map((thisCollection) => (
            <Field
              size="sm"
              backgroundColor={`${
                thisCollection.collectionName === collection.collectionName
                  ? "#bee3f8"
                  : "white"
              }`}
              borderRadius="full"
              textColor="gray.700"
              key={thisCollection.name}
              as={Button}
              name="collection"
              value={thisCollection.collectionName}
              onClick={() => onCollectionChange(thisCollection.collectionName)}
            >
              <Text fontWeight="bold">{thisCollection.name}</Text>
            </Field>
          ))}
        </Stack>
      </FormControl>
      <Box>
        <Accordion allowToggle paddingTop="4">
          <AccordionItem border="none">
            <AccordionButton
              boxShadow={"md"}
              w={"192px"}
              border="1px solid"
              rounded={"md"}
              color={"blue.500"}
              fontWeight={500}
              fontSize={"16px"}
              _expanded={{bg: "blue.500", color: "white"}}
            >
              <Box flex="1" textAlign="left">
                Filters
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <HStack justify="space-between" paddingBottom="2">
                <SavedReportsDropdown
                  savedReports={savedReports}
                  onSelectSavedReports={onSelectSavedReports}
                  selectedSavedReport={selectedSavedReport}
                  customReportName={customReportName}
                  handleChange={formikProps.handleChange}
                  selectedCollection={collection.collectionName}
                  onSaveReport={onSaveReport}
                  formikProps={formikProps}
                  onDeleteReport={onDeleteReport}
                />
              </HStack>
              <GroupByInput
                collection={collection}
                formikProps={formikProps}
                selectedGroupBy={formikProps.values.groupBy}
                fields={fields}
                queryData={queryData}
              />

              <ChakraProvider theme={chakraTheme}>
                <QueryBuilderChakra>
                  <QueryBuilder
                    query={formikProps.values.query}
                    onQueryChange={(evt) => {
                      formikProps.setValues({
                        ...formikProps.values,
                        query: evt,
                      });
                    }}
                    fields={fields}
                    addRuleToNewGroups
                    controlClassnames={{queryBuilder: "queryBuilder-branches"}}
                    controlElements={{
                      operators,
                      removeGroupAction: DeleteItemButton,
                      removeRuleAction: DeleteItemButton,
                    }}
                    getOperators={() => operators}
                  />
                </QueryBuilderChakra>
              </ChakraProvider>
              <Button
                onClick={() =>
                  queryData(
                    formikProps.values.collection,
                    formikProps.values.query,
                    formikProps.values.groupBy,
                    formikProps
                  )
                }
                colorScheme="green"
                leftIcon={<CheckIcon />}
                marginTop="2"
              >
                Apply
              </Button>
            </AccordionPanel>
            <HStack justify="space-between">
              <DisplayedColumnsModal collection={collection} />
              <ExportToExcelButton
                data={resultingData}
                activeColumns={formikProps.values.activeColumns}
                groupBy={formikProps.values.groupBy}
              />
            </HStack>
          </AccordionItem>
        </Accordion>
      </Box>
    </Box>
  );
};

const GroupByInput = ({
  collection,
  formikProps,
  selectedGroupBy = [],
  fields,
  queryData,
}) => {
  const getFieldLabel = (fieldName) => {
    const field = fields.find((f) => f.name === fieldName);
    return field ? field.label : fieldName; // Fallback to fieldName if label is not found
  };

  return (
    <div>
      <HStack>
        <div>
          <Text
            ml="2"
            mt="2.5"
            fontWeight="semibold"
            color="blue.600"
            fontSize="15px"
          >
            Group By:
          </Text>
        </div>
        <ul>
          {selectedGroupBy.map((field) => {
            return (
              <Box
                display="inline-flex"
                alignItems="center"
                rounded="md"
                p="1.5"
                position="relative"
                key={field}
              >
                <Button
                  colorScheme="blue"
                  variant="outline"
                  size="sm"
                  bg="blue.50"
                >
                  {getFieldLabel(field)}
                  <CloseButton
                    size="sm"
                    ml="2"
                    mr="-2"
                    color="blue.600"
                    onClick={() => {
                      const updatedGroupBy =
                        formikProps.values.groupBy?.filter(
                          (groupByValue) => groupByValue !== field
                        ) || [];
                      formikProps.setValues({
                        ...formikProps.values,
                        groupBy: updatedGroupBy,
                      });
                      queryData(
                        formikProps.values.collection,
                        formikProps.values.query,
                        updatedGroupBy,
                        formikProps
                      );
                    }}
                  />
                </Button>
              </Box>
            );
          })}
        </ul>
        <GroupFieldsModal
          collection={collection}
          source="groupBy"
          formikProps={formikProps}
          queryData={queryData}
        />
      </HStack>
    </div>
  );
};
