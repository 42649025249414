import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Avatar,
  Badge,
  Flex,
} from "@chakra-ui/react";
import Information from "./Information";
import Intake from "./Intake";
import Forms from "./Forms";
import Appointments from "./Appointments";
import Non_Sessions from "./Non_Sessions";
import Charts from "./Charts";
import Files from "./Files";
import Prescriptions from "./Prescriptions";
import DMs from "./DMs";
import Sleep from "./Sleep";
import Journal from "./Journal";
import Settings from "./Settings";
import Products from "./Products";
import {useContext} from "react";
import {getAge} from "./helpers/getAge";
import {DashStateContext, PatientContext} from "../../pages/Patients";
import Billing from "../../pages/Billing";
import {PatientMembership} from "./PatientMembership/PatientMembership";
import CliniciansByPatient from "./CliniciansByPatient";
import Requisitions from "./Requisitions";
import {useState, useMemo} from "react";
import {ButtonTasks} from "../Tasks/ButtonTasks";
export default function Patient(props) {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const [tabIndex, setTabIndex] = useState(
    parseInt(props.patientQueryParams?.tab) || 0
  );
  const [apptLinkToChart, setApptLinkToChart] = useState(
    props.patientQueryParams?.aid ? {aid: props.patientQueryParams?.aid} : null
  );
  const handleTabsChange = (index) => {
    if (index !== 6) {
      setApptLinkToChart("");
    }
    setTabIndex(index);
  };

  const handleLinkToChart = (appt) => {
    setApptLinkToChart(appt);
    setTabIndex(6);
  };

  const cliniciansMap = useMemo(() => {
    let clins = dashState.doctor ? [dashState.doctor] : dashState.doctors;
    return clins.reduce((acc, ele) => {
      acc[ele.did] = ele;
      return acc;
    }, {});
  }, [dashState.doctor, dashState.doctors]);

  return (
    <Flex flex={1} direction="column" overflowY="hidden">
      <Flex mb="2" align={"center"}>
        <Flex
          align={"center"}
          fontSize="2xl"
          as="span"
          fontWeight="bold"
          ml="2"
        >
          <Avatar
            size="sm"
            name={patient.fName + " " + patient.lName}
            mr="3"
            bg={"blue.500"}
            color={"white"}
          />
          {patient.lName + ", " + patient.fName}
        </Flex>
        {patient.dob && (
          <>
            <Text ml="3" fontSize="xl" as="span" fontWeight="medium">
              {getAge(patient.dob)} y/o
            </Text>
          </>
        )}
        {patient.gender && (
          <>
            <Text ml="2" fontSize="lg" as="span" fontWeight="regular">
              {patient.gender}
            </Text>
          </>
        )}
        {
          <Flex gap="2" align={"center"}>
            <Text ml="2" fontSize="lg" as="span" fontWeight="regular">
              assigned to
            </Text>
            <CliniciansByPatient
              patient={patient}
              assignedClinicians={[]
                .concat(patient.did)
                .filter((e) => cliniciansMap[e])
                .map((e) => cliniciansMap[e])}
              isAdmin={dashState.userType === "admin"}
              clinicians={dashState.doctors}
              schState={dashState}
              dispatch={(payload) => {
                if (payload.type === "UPDATE_PATIENT" && payload.payload.did) {
                  setPatient((prevPatient) => ({
                    ...prevPatient,
                    did: [...payload.payload.did] || [],
                  }));
                }
                props.dispatch(payload);
              }}
            />
          </Flex>
        }
        {patient.inactive && (
          <Badge
            rounded="md"
            variant="outline"
            colorScheme={"orange"}
            fontSize={"12px"}
            p="1"
            px="2"
            mt="-2"
            ml="4"
            textAlign={"center"}
          >
            &bull; Inactive
          </Badge>
        )}
        {patient.deceased && (
          <Badge
            rounded="md"
            variant="outline"
            colorScheme={"red"}
            fontSize={"12px"}
            p="1"
            px="2"
            mt="-2"
            ml="4"
            textAlign={"center"}
          >
            &bull; Deceased
          </Badge>
        )}
        {patient.deleted && (
          <Badge
            rounded="md"
            variant="outline"
            colorScheme={"gray"}
            fontSize={"12px"}
            p="1"
            px="2"
            mt="-2"
            ml="4"
            textAlign={"center"}
          >
            &bull; Deleted
          </Badge>
        )}
      </Flex>

      <Tabs
        flex={1}
        isFitted={true}
        overflowY={"scroll"}
        display="flex"
        flexDirection={"column"}
        flexWrap={"wrap"}
        isLazy
        index={tabIndex}
        onChange={handleTabsChange}
      >
        <TabList overflowX="scroll" overflowY={"hidden"} w="100%">
          {/*<Tab>Summary</Tab>*/}
          <Tab>Information</Tab>
          <Tab>Intake</Tab>
          <Tab>Appointments</Tab>
          <Tab>Forms</Tab>
          <Tab>Services</Tab>
          <Tab>Products</Tab>
          <Tab>Charts</Tab>
          <Tab>Requisitons</Tab>
          <Tab>Files</Tab>
          <Tab>Prescriptions</Tab>
          <Tab>DMs</Tab>
          <Tab>Sleep</Tab>
          <Tab>Journal</Tab>
          <Tab>Billing</Tab>
          <Tab>Membership</Tab>
          <Tab>Settings</Tab>
        </TabList>

        <Box pr="1" flex={1} overflow="hidden">
          <TabPanels height={"full"} overflow="scroll">
            {/*<TabPanel>
              <Summary />
            </TabPanel>*/}
            <TabPanel>
              <Information />
            </TabPanel>
            <TabPanel>
              <Intake />
            </TabPanel>
            <TabPanel h={"full"} overflow="hidden">
              <Appointments {...{handleLinkToChart}} />
            </TabPanel>
            <TabPanel>
              <Forms />
            </TabPanel>

            <TabPanel h={"full"} overflow="hidden">
              <Non_Sessions />
            </TabPanel>
            <TabPanel
              h={"full"}
              display={"flex"}
              flexDirection={"column"}
              overflow="hidden"
            >
              <Products />
            </TabPanel>
            <TabPanel>
              <Charts {...{apptLinkToChart, setApptLinkToChart}} />
            </TabPanel>
            <TabPanel>
              <Requisitions />
            </TabPanel>
            <TabPanel>
              <Files />
            </TabPanel>
            <TabPanel>
              <Prescriptions />
            </TabPanel>
            <TabPanel>
              <DMs />
            </TabPanel>
            <TabPanel>
              <Sleep />
            </TabPanel>
            <TabPanel>
              <Journal />
            </TabPanel>
            {
              <TabPanel
                h={"full"}
                display={"flex"}
                flexDirection={"column"}
                overflow="hidden"
                paddingBottom={0}
              >
                <Billing renderedFromPatient />
              </TabPanel>
            }
            <TabPanel>
              <PatientMembership />
            </TabPanel>
            <TabPanel>
              <Settings setPatient={props.setPatient} />
            </TabPanel>
          </TabPanels>
        </Box>
      </Tabs>
      <ButtonTasks dashState={props.dashState} patient={patient} />
    </Flex>
  );
}
