import React, {
  useState,
  useMemo,
  useRef,
  useLayoutEffect,
  useContext,
} from "react";
import {lightFormat} from "date-fns";
import {TruncatedTextWithTooltip} from "./CliniciansTable";
import {MdLocationOn} from "react-icons/md";
import Swal from "sweetalert2";
import ApptType from "./appointments/AppointmentModal.js/ApptType";
import {crud} from "../crudRequests";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import swal from "sweetalert";
import {isoToApptDate} from "../additional_files/helpers";
import {notificationsData} from "../additional_files/notifications";
import AppointmentNote from "./appointments/Notes";
import {useToast} from "@chakra-ui/react";
import {BsPersonCircle, BsClock} from "react-icons/bs";
import {MdModeEditOutline} from "react-icons/md";
import {FaWalking} from "react-icons/fa";
import RescheduleAppointment from "./ApptRescheduleModal";
import {SocketContext} from "../additional_files/context";
import renderBlurredText from "../utils/renderBlurredText";
import verifyAccess from "../utils/verifyAccess";
import PaymentInformation from "./appointments/payment";
import {getJitsiJWT} from "./Patients/Appointments/helpers/jitsiFunctions";
import AidClaimModal from "./appointments/clinicAidClaimModal";
import CancelAppointmet from "./appointments/AppointmentModal.js/CancelAppointmet";
import cancelConfirmation from "./appointments/AppointmentModal.js/cancelConfirmation";
import MeetingLinks from "./appointments/AppointmentModal.js/MeetingLinks";
import DeleteRecurrence from "./appointments/AppointmentModal.js/DeleteRecurrence";
import {createHashTable} from "../utils/createHashTable";
import noShowConfirmation from "./appointments/AppointmentModal.js/noShowConfirmation";
const weekday = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export default function AppointmentModal({
  dispatch,
  schState,
  InsurersMap,
  isFromPatient,
}) {
  const navigate = useNavigate();
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const [cancellationModalData, setCancellationModalData] = useState({
    title: "",
    status: "",
  });
  const patients = useMemo(
    () => createHashTable(schState.patients, "pid"),
    [schState.patients.length]
  );
  const [currentPatientName, setCurrentPatientName] = useState();
  const [currentTime, setCurrentTime] = useState();
  const [sendingAidClaim, setSendingAidClaim] = useState();
  const [doctor, setDoctor] = useState();
  const apptModalRef = useRef(null);
  const [currentApptLink, setCurrentApptLink] = useState();
  const [left, setLeft] = useState(`100%`);
  const [hideList, setHideList] = useState(`100%`);
  const socket = useContext(SocketContext);
  const toast = useToast();
  const blurredName = useMemo(
    () => renderBlurredText(currentPatientName),
    [currentPatientName]
  );
  let identifier = schState.selectedAppointment;
  const service = schState.services.find(s => s.serviceId === identifier?.serviceId)

  useEffect(() => {
    identifier && !isFromPatient
      ? setLeft(`calc(100% - ${apptModalRef.current.offsetWidth}px)`)
      : setLeft(`100%`);
  }, [identifier]);

  useEffect(
    function () {
      function apptUpdate(appt) {
        let {time} = appt.ISOdate
          ? isoToApptDate(appt)
          : {date: appt.date, time: appt.time};
        const patient = patients[appt.pid];
        setCurrentPatientName(`${patient.lName}, ${patient.fName}`);
        setCurrentTime(time);
        setCurrentApptLink(appt["clinicApptLink"]);

        if (schState.userType === "admin") {
          let index = schState.doctorsPayload[appt["did"]].index;

          setDoctor(schState.doctors[index]);
        } else {
          setDoctor(schState.selectedDoctor);
        }
      }

      if (identifier) {
        apptUpdate(identifier);
        setHideList(true);
      }
    },
    [
      identifier,
      schState.userType,
      schState.doctorsPayload,
      schState.doctors,
      schState.selectedDoctor,
    ]
  );

  return (
    <section
      className=" h-full bg-[#F6F5FE] fixed slide top-0 bottom-0 z-[999]"
      ref={apptModalRef}
      style={{
        opacity: 1,
        right: isFromPatient ? 0 : null,
        left: !isFromPatient ? left : null,
      }}
      onClick={(e) => {
        e.stopPropagation();
        setHideList(true);
      }}
    >
      {showCancellationModal && (
        <CancelAppointmet
          {...{
            onConfirm: async ({reason, allowAction, status, noShowFeeOverride}) => {
              status === "no-show"
              ? 
              noShowConfirmation({
                toast,
                allowAction,
                reason,
                appt: identifier,
                state: schState,
                dispatch,
                socket,
                noShowFeeOverride
              })
              :
              cancelConfirmation({
                toast,
                allowAction,
                reason,
                appt: identifier,
                state: schState,
                socket,
                dispatch,
              })},
            onClose: setShowCancellationModal,
            appt: identifier,
            isOpen: showCancellationModal,
            noShowFee: service.noShowFee,
            ...cancellationModalData,
          }}
        />
      )}
      <div className="h-full whitespace-nowrap flex flex-col p-2 px-4 pr-2 w-[25rem] mt-3 space-y-3">
        <div className="h-[calc(100vh-80px)]  overflow-scroll p-2 sbar2">
          <div className="flex flex-col items-start space-y-1">
            <div className="flex items-end justify-between w-full pr-2">
              <p className="text-[#5754FF] text-[1.7rem] ">
                <span className="font-semibold">
                  {verifyAccess(schState, "fName")
                    ? currentPatientName?.slice(0, 17) +
                      (currentPatientName?.length > 17 ? "..." : "")
                    : blurredName}
                </span>{" "}
                <span>with</span>
              </p>
            </div>
            <div className="flex items-center p-0.5 rounded-md">
              {identifier?.assignedProfessional === "technician" ? (
                <p className="text-dark text-[1.2rem] leading-5 font-semibold">
                  {identifier?.technician || ""}
                </p>
              ) : (
                <>
                  <span className="flex justify-center h-10 items-center">
                    {doctor?.photo ? (
                      <img
                        src={doctor.photo}
                        alt=""
                        className="hover:cursor-pointer hover:bg-[] h-full rounded-md drop-shadow-lg"
                      />
                    ) : (
                      <BsPersonCircle className="text-[2.5rem] text-[#5754FF]/80" />
                    )}
                  </span>
                  <div className="ml-2 flex flex-col">
                    <p className="text-dark text-[16px] leading-5 font-semibold">
                      {`${doctor?.lastName}, ${doctor?.firstName}` || ""}
                    </p>
                    <span className="font-light text-[10px] text-dark">
                      {doctor?.accreditations}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>

          {
            <DateComponent
              {...{time: currentTime, identifier, dispatch, schState, socket}}
            />
          }
          {identifier?.recurrenceId && (
            <DeleteRecurrence
              appt={identifier}
              state={schState}
              dispatch={dispatch}
            />
          )}
          {
            <Status
              appt={identifier}
              {...{
                schState,
                dispatch,
                hideList,
                setHideList,
                socket,
                openCancellationModal: ({status, title}) => {
                  setCancellationModalData({status, title});
                  setShowCancellationModal(true);
                },
              }}
            />
          }
          {
            <Services
              appt={identifier}
              {...{schState, dispatch, hideList, setHideList, socket}}
            />
          }
          {identifier && InsurersMap[identifier?.tpp] && (
            <div className="space-y-1 mt-5">
              <p className="text-[#A1A1A1] text-xs">Third Party Payer</p>
              <div className="flex items-start space-x-1 relative">
                <div className="bg-[#97A1FF] leading-6 font-medium rounded-lg p-2 px-3 text-sm flex flex-col text-white relative w-full">
                  <span>{InsurersMap[identifier?.tpp]?.name || "N/A"}</span>
                </div>
              </div>
            </div>
          )}
          <AppointmentNote
            appt={identifier}
            {...{schState, dispatch, hideList, setHideList, socket}}
          />
          <button
            className="w-1/2 bg-[#5754FF] mt-2 flex-1 cursor-pointer text-white py-2 hover:bg-med rounded-lg font-medium flex justify-center items-center"
            onClick={() => {
              navigate(`/patients`, {
                state: {
                  ...schState,
                  selectedPid: identifier?.pid,
                  patientChartAid: identifier?.aid,
                  patientChartTab: 6,
                },
              });
            }}
          >
            Create chart
          </button>
          <PaymentInformation
            appointment={identifier}
            {...{
              state: schState,
              dispatch,
              hideList,
              setHideList,
              socket,
              sendingAidClaim,
              setSendingAidClaim,
            }}
          />
          <MeetingLinks appointment={identifier} state={schState} />
        </div>
        {sendingAidClaim && identifier && (
          <AidClaimModal
            appt={identifier}
            action={() => setSendingAidClaim(false)}
            state={schState}
            dispatch={dispatch}
            patient={patients[identifier.pid]}
            clinician={doctor}
          />
        )}

        <div className="absolute  bottom-3 flex w-full pl-1 pr-8">
          <button className="ml-1 flex-1 focus:outline-none justify-center flex space-x-2 items-center bg-[#3D50FF]/80 rounded-md p-1 px-3 w-full text-white whitespace-nowrap">
            <span className="text-xs  h-6 w-6  flex justify-center items-center">
              <img
                alt=""
                src="/images/Doctor/video.png"
                className="w-[1.1rem]"
              />
            </span>
            <span
              className="font-medium text-sm "
              onClick={async () => {
                if (schState.userType === "admin")
                  toast({
                    title: "You are not authorized to join this call.",
                    status: "info",
                    description:
                      "Only the patient and clinician associated with this appointment are allowed to go in the call.",
                    duration: 3000,
                    isClosable: true,
                    position: "top",
                  });
                else {
                  if (identifier?.clinicApptLink) {
                    // Will display the loading toast until the promise is either resolved
                    // or rejected.
                    toast.promise(
                      getJitsiJWT(
                        schState,
                        identifier.aid,
                        identifier.pid,
                        schState.db,
                        schState.selectedDoctor,
                        identifier.clinicApptLink
                      ),
                      {
                        success: {
                          title: "Authenticated",
                          description: "Successfully joined meeting!",
                        },
                        error: {
                          title: "Please try again",
                          description:
                            "Something wrong authenticating you to the meeting",
                        },
                        loading: {
                          title: "Authenticating...",
                          description: "Please wait",
                        },
                      }
                    );
                  }
                }
              }}
            >
              Join Call
            </span>
          </button>

          {!isFromPatient && (
            <button className="ml-3 flex-1 focus:outline-none flex space-x-2 items-center bg-[#3D50FF]/60 rounded-md p-1 px-3 w-full text-white whitespace-nowrap">
              <span className="text-sm  h-6 w-6  flex justify-center items-center">
                <img
                  alt=""
                  src="/images/Doctor/patient.png"
                  className="w-[1.1rem]"
                />
              </span>
              <span
                className="font-medium text-sm"
                onClick={() => {
                  navigate(`/patients`, {
                    state: {
                      firstLoading: true,
                      ...schState,
                      selectedPid: identifier?.pid,
                    },
                  });
                }}
              >
                Go to Patient
              </span>
            </button>
          )}
        </div>
      </div>
    </section>
  );
}

function DateComponent({time, identifier: appt, dispatch, schState, socket}) {
  const [rescheduleRef, setRescheduleRef] = useState(false);
  const [date, setDate] = useState(null);
  const [duration, setDuration] = useState(null);

  useLayoutEffect(() => {
    if (appt) {
      setDate(new Date(appt.ISOdate));
      setDuration(appt.duration);
    }
  }, [appt]);

  let dateString = date?.toDateString();

  return (
    <div className=" w-full mt-6 flex justify-between items-start pr-2">
      <div className="">
        <div className="flex space-x-3 items-center relative w-full">
          <div>
            <p className="text-[#A1A1A1] text-xs  mb-1">booked for</p>
            <div className="rounded-lg overflow-hidden bg-[#E9E8FB] text-center">
              <p className="bg-[#6271FF] text-xs font-medium p-[2px] text-white">
                {weekday[date?.getDay() || 0]}
              </p>
              <p className="text-[#6271FF] font-bold text-lg px-5 py-1 space-x-1">
                <span>{dateString?.split(" ")[1]}</span>
                <span>{dateString?.split(" ")[2]}</span>
              </p>
            </div>
          </div>
          <div className="flex  relative items-start py-1">
            <p className="flex flex-col leading-3 font-semibold text-center text-xs bg-[#F6F5FE] rounded-t-lg pt-1 -mr-4 z-10">
              <span className="bg-[#6271FF]  w-16 rounded-full leading-4 text-white px-2 p-1 mb-0.5">
                {time?.toLowerCase() || ""}
              </span>
              <span className="text-[#6271FF] text-[10px]">
                {duration || ""}
              </span>
            </p>
            <p className="">
              <BsClock className="text-[#6271FF] text-[3rem] stroke-[0.1]" />
            </p>
            {!appt?.cancelled && (
              <p className="absolute top-[-12px] right-[-12px]">
                <span
                  className="hover:cursor-pointer text-[#A1A1A1] h-[1.5rem] w-[1.5rem] overflow-hidden group rounded-lg flex justify-center items-center drop-shadow-md"
                  onClick={() => {
                    setRescheduleRef(true);
                  }}
                >
                  <MdModeEditOutline />
                </span>
              </p>
            )}
          </div>
        </div>
        {appt?.appointmentType === "live" && appt?.address && (
          <div className="flex  items-center mt-4">
            <MdLocationOn className="text-[#3182ce]" />
            <div className="w-[12rem]">
              <TruncatedTextWithTooltip
                color={"blue.500"}
                w="full"
                fontSize={"sm"}
              >
                {appt?.appointmentType === "live" ? appt?.address || "" : ""}
              </TruncatedTextWithTooltip>
            </div>
          </div>
        )}
      </div>
      <ApptType full appt={appt} />

      {rescheduleRef && appt && (
        <RescheduleAppointment
          schState={schState}
          appointment={appt}
          stateChanger={setRescheduleRef}
          dispatch={dispatch}
          socket={socket}
        />
      )}
    </div>
  );
}

function Services({appt, schState, dispatch, hideList, setHideList, socket}) {
  const [service, setService] = useState(null);
  const [show, setShow] = useState(false);
  const [pLinkRef, setPlinkRef] = useState(false);
  const [amountModal, setAmountModal] = useState(false);
  const toast = useToast();
  useLayoutEffect(() => {
    if (appt) {
      setService(appt.service);
      setShow(false);
    }
  }, [appt]);

  useLayoutEffect(() => {
    if (hideList) {
      setShow(false);
    }
  }, [hideList]);

  const updateService = ({
    appt,
    service,
    schState,
    dispatch,
    socket,
    toast,
  }) => {
    changeAppointmentService({
      appt,
      service: service,
      schState,
      dispatch,
      socket,
      toast,
    });
  };

  return (
    <div className="space-y-1 justify-between w-full">
      <div className="space-y-1 mt-5">
        <p className="text-[#A1A1A1] text-xs">service</p>
        <div className="flex items-start space-x-1 relative">
          <div className="bg-[#97A1FF] leading-6 font-medium rounded-lg p-2 px-3 text-sm flex flex-col text-white relative w-full">
            <span>
              {service?.slice(0, 27) + (service?.length > 27 ? "..." : "")}
            </span>
            {show && (
              <ServiceList
                {...{
                  appt,
                  schState,
                  dispatch,
                  setShow,
                  updateService,
                  socket,
                  toast,
                }}
              />
            )}
          </div>
          {/*<span
            className="text-[#A1A1A1] hover:cursor-pointer relative"
            onClick={(e) => {
              e.stopPropagation();
              setShow(true);
              setHideList(false);
            }}
          >
            <MdModeEditOutline />
          </span>*/}
        </div>
      </div>
    </div>
  );
}

function ServiceList({
  appt,
  schState,
  dispatch,
  setShow,
  updateService,
  socket,
  toast,
}) {
  return (
    <div className="w-full h-20 absolute top-0 left-0 show  p-1 overflow-hidden rounded-lg bg-[#97A1FF] py-2">
      <ul className="overflow-y-scroll overflow-x-hidden space-y-1 sbar2 h-full text-xs">
        {schState.services.map((service, i) => {
          return (
            <li
              className="hover:bg-[#a8aff2] text-white cursor-pointer"
              key={i}
              onClick={(e) => {
                if (appt?.service !== service.serviceName) {
                  updateService({
                    appt,
                    service: service.serviceName,
                    schState,
                    dispatch,
                    socket,
                    toast,
                  });
                }
                setShow(false);
              }}
            >
              {service.serviceName}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function changeAppointmentService({
  appt,
  service,
  schState,
  dispatch,
  socket,
  toast,
}) {
  if (appt?.aid && appt.service !== service)
    swal({
      title: "Are you sure you want to change the service of this appointment?",
      text: `The servicefor this appointment will change to ${service}.`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((value) => {
      if (value) {
        const updateBody = {
          ...appt,
          service,
        };
        let {_id, ...data} = updateBody;
        let org = schState.organization;
        let location = schState.locations.find(
          (l) => l.lid === schState.selectedLocation
        );
        let sender = location?.name;

        crud(
          schState,
          [
            {
              db: schState.db,
              collection: "appointments",
              parameters: [{aid: data.aid}, {$set: data}],
              method: "updateOne",
            },
          ],
          {
            email: {
              type: "update_appointment",
              content: data,
              options: {service},
              sender,
              org,
              location,
            },
          }
        )
          .then(async (res) => {
            dispatch({type: "UPDATE_APPOINTMENT", payload: updateBody});
            let {ntf, ntfList} = notificationsData(
              schState.userType,
              "Updated appointment",
              updateBody,
              schState
            );
            socket?.emit?.("update_appt", updateBody, {ntf});

            let requestObjs = [
              {
                db: schState.db,
                collection: "notifications",
                parameters: [
                  {userType: ntfList[0].userType},
                  {$push: {[ntfList[0].id]: ntfList[0].notification}},
                  {upsert: true},
                ],
                method: "findOneAndUpdate",
              },
              {
                db: schState.db,
                collection: "notifications",
                parameters: [
                  {userType: ntfList[1].userType},
                  {$push: {[ntfList[1].id]: ntfList[0].notification}},
                  {upsert: true},
                ],
                method: "findOneAndUpdate",
              },
            ];
            await crud(schState, requestObjs);

            toast({
              title: "The appointment has been successfully booked!",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
}

function Status({
  appt,
  schState,
  hideList,
  setHideList,
  dispatch,
  socket,
  openCancellationModal,
}) {
  const [st, setSt] = useState("Unconfirmed");
  const [show, setShow] = useState(false);
  const toast = useToast();
  let colorsMap = {
    "No Show": "#000000",
    Cancelled: "#FF0000",
    Arrived: "#2dd4bf",
    Confirmed: "#5754FF",
    Rescheduled: "#FF9900",
    Unconfirmed: "#8F8CFF",
  };

  useLayoutEffect(() => {
    if (hideList) {
      setShow(false);
    }
  }, [hideList]);

  function status(appt) {
    let statusMap = {
      noShow: "No Show",
      cancelled: "Cancelled",
      arrived: "Arrived",
      pConfirmed: "Confirmed",
      rescheduled: "Rescheduled",
    };

    for (let [key, val] of Object.entries(statusMap)) {
      if (appt[key]) return val;
    }
    return "Unconfirmed";
  }

  useLayoutEffect(() => {
    appt && setSt(status(appt));
  }, [appt]);

  return (
    <div className="flex justify-between items-center  mt-1">
      <div className="relative space-y-1 mt-2">
        <p className="text-[#A1A1A1] text-xs">current status</p>
        <p
          className="text-white text-sm rounded-md p-2 px-3 w-28 font-medium"
          style={{backgroundColor: colorsMap[st]}}
        >
          {st}
        </p>
      </div>

      <div className="space-y-1 mt-2">
        <p className="text-[#A1A1A1] text-xs">change status</p>
        <div className="space-y-1 mr-2">
          <div
            className={`${
              appt?.noShow || appt?.paymentStatus === "paid"
                ? "cursor-not-allowed"
                : "cursor-pointer"
            } bg-[#000000] text-white text-xs px-2 py-1.5 overflow-hidden group rounded-lg flex justify-center items-center drop-shadow-lg`}
            onClick={(evt) => {
              appt &&
                !appt.noShow &&
                appt?.paymentStatus !== "paid" &&
                openCancellationModal({
                  status: "no-show",
                  title:
                    "Are you sure you want to change the status to No Show?",
                });
              /*  if (appt.paidByMembership) {
                const apptPatient = schState.patients.find(
                  (patient) => patient.pid === appt.pid
                );
                const membershipData = apptPatient.memberships?.find(
                  (membershipData) => {
                    if (membershipData.uuid === appt.paidByMembership) {
                      return true;
                    }
                    return false;
                  }
                );
                membershipData.selectedServices[appt.serviceId] = {
                  ...membershipData.selectedServices[appt.serviceId],
                  consumed:
                    parseInt(
                      membershipData.selectedServices[appt.serviceId]
                        .consumed || 0
                    ) + 1,
                  reserved:
                    parseInt(
                      membershipData.selectedServices[appt.serviceId].reserved
                    ) - 1,
                };
                crud(
                  schState,
                  [
                    {
                      db: schState.db,
                      collection: "patients",
                      parameters: [
                        {pid: appt.pid},
                        {
                          $set: {
                            "memberships.$[membership].selectedServices":
                              membershipData.selectedServices,
                          },
                        },
                        {
                          arrayFilters: [
                            {"membership.uuid": appt.paidByMembership},
                          ],
                        },
                      ],
                      method: "updateOne",
                    },
                  ],
                  null
                );
                const updatedMemberships = (apptPatient.memberships || []).map(
                  (mData) => {
                    if (mData.uuid === appt.paidByMembership) {
                      return {
                        ...mData,
                        selectedServices: membershipData.selectedServices,
                      };
                    }
                    return mData;
                  }
                );
                dispatch({
                  type: "UPDATE_PATIENT",
                  pid: appt.pid,
                  payload: {
                    memberships: updatedMemberships,
                  },
                });
              }
              return (
                !appt?.cancelled &&
                changeAppointmentStatus(
                  appt,
                  "No Show",
                  schState,
                  dispatch,
                  socket,
                  toast
                )
              );*/
            }}
          >
            No Show &nbsp;
            <img
              alt=""
              src="/images/Doctor/noShow_white.png"
              className="w-[1.1rem]"
            />
          </div>
          <div
            className={`${
              appt?.cancelled || appt?.paymentStatus === "paid"
                ? "cursor-not-allowed"
                : "cursor-pointer"
            }  bg-[#FF0000] text-white text-xs px-2 py-1.5 overflow-hidden group rounded-lg flex justify-center items-center drop-shadow-lg`}
            onClick={async () => {
              appt &&
                !appt.cancelled &&
                appt?.paymentStatus !== "paid" &&
                openCancellationModal({
                  status: "cancel-appt",
                  title: "Are you sure you want to cancel this appointment?",
                });
              /*if (!appt?.cancelled) {
                const {value: text, isConfirmed} = await Swal.fire({
                  icon: "warning",
                  input: "textarea",
                  title: `Are you sure you want to cancel this appointment?`,
                  inputPlaceholder: "Describe the reason for cancellation...",
                  inputAttributes: {
                    "aria-label": "Describe the reason for cancellation",
                  },
                  showCancelButton: true,
                });

                if (text && isConfirmed) {
                  const updateBody = {
                    ...appt,
                    cancelled: true,
                    cancellationReason: text.trim(),
                    noShow: false,
                    pConfirmed: false,
                    arrived: false,
                  };
                  let {_id, ...data} = updateBody;

                  let location = await crud(schState, [
                    {
                      db: schState.db,
                      collection: "locations",
                      parameters: [{lid: schState.selectedLocation}],
                      method: "findOne",
                    },
                  ]);
                  location = location.data[0];
                  let sender = location?.name;

                  crud(
                    schState,
                    [
                      {
                        db: schState.db,
                        collection: "appointments",
                        parameters: [{aid: data.aid}, {$set: data}],
                        method: "updateOne",
                      },
                    ],
                    {
                      email: {
                        type: "update_appointment",
                        content: data,
                        options: {status: "Cancelled"},
                        sender,
                        org: schState?.organization,
                        location,
                      },
                    }
                  )
                    .then(async (res) => {
                      dispatch({
                        type: "UPDATE_APPOINTMENT",
                        payload: updateBody,
                      });
                      let {ntf, ntfList} = notificationsData(
                        schState.userType,
                        "Updated appointment",
                        updateBody,
                        schState
                      );
                      socket?.emit?.("update_appt", updateBody, {ntf});

                      let requestObjs = [
                        {
                          db: schState.db,
                          collection: "notifications",
                          parameters: [
                            {userType: ntfList[0].userType},
                            {$push: {[ntfList[0].id]: ntfList[0].notification}},
                            {upsert: true},
                          ],
                          method: "findOneAndUpdate",
                        },
                        {
                          db: schState.db,
                          collection: "notifications",
                          parameters: [
                            {userType: ntfList[1].userType},
                            {$push: {[ntfList[1].id]: ntfList[0].notification}},
                            {upsert: true},
                          ],
                          method: "findOneAndUpdate",
                        },
                      ];
                      await crud(schState, requestObjs);
                      if (appt.paidByMembership) {
                        const apptPatient = schState.patients.find(
                          (patient) => patient.pid === appt.pid
                        );
                        const membershipData = apptPatient.memberships?.find(
                          (membershipData) => {
                            if (membershipData.uuid === appt.paidByMembership) {
                              return true;
                            }
                            return false;
                          }
                        );
                        membershipData.selectedServices[appt.serviceId] = {
                          ...membershipData.selectedServices[appt.serviceId],
                          reserved:
                            parseInt(
                              membershipData.selectedServices[appt.serviceId]
                                .reserved
                            ) - 1,
                        };
                        crud(
                          schState,
                          [
                            {
                              db: schState.db,
                              collection: "patients",
                              parameters: [
                                {pid: appt.pid},
                                {
                                  $set: {
                                    "memberships.$[membership].selectedServices":
                                      membershipData.selectedServices,
                                  },
                                },
                                {
                                  arrayFilters: [
                                    {"membership.uuid": appt.paidByMembership},
                                  ],
                                },
                              ],
                              method: "updateOne",
                            },
                          ],
                          null
                        );
                        const updatedMemberships = (
                          apptPatient.memberships || []
                        ).map((mData) => {
                          if (mData.uuid === appt.paidByMembership) {
                            return {
                              ...mData,
                              selectedServices: membershipData.selectedServices,
                            };
                          }
                          return mData;
                        });
                        dispatch({
                          type: "UPDATE_PATIENT",
                          pid: appt.pid,
                          payload: {
                            memberships: updatedMemberships,
                          },
                        });
                      }
                      // setCloseModals(true);

                      toast({
                        title:
                          "The appointment has been successfully canceled!",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                      });
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
                } else {
                  if (!text && isConfirmed)
                    toast({
                      title: "Reason for cancellation.",
                      description:
                        "You must indicate the reason for the cancellation!",
                      status: "error",
                      duration: 5000,
                      isClosable: true,
                    });
                }
              }*/
            }}
          >
            Cancel &nbsp;
            <img
              alt=""
              src="/images/Doctor/cancelappt_white.png"
              className="w-[1.1rem]"
            />
          </div>
          <div
            className={`${
              appt?.cancelled || appt?.paymentStatus === "paid" || appt?.arrived
                ? "cursor-not-allowed"
                : "cursor-pointer"
            }  bg-[#2dd4bf] text-white text-xs px-2 py-1.5 overflow-hidden group rounded-lg flex justify-center items-center drop-shadow-lg`}
            onClick={() => {
              if (
                appt?.cancelled ||
                appt?.arrived ||
                appt?.paymentStatus === "paid"
              )
                return;
              if (appt.paidByMembership) {
                const apptPatient = schState.patients.find(
                  (patient) => patient.pid === appt.pid
                );
                const membershipData = apptPatient.memberships?.find(
                  (membershipData) => {
                    if (membershipData.uuid === appt.paidByMembership) {
                      return true;
                    }
                    return false;
                  }
                );
                membershipData.selectedServices[appt.serviceId] = {
                  ...membershipData.selectedServices[appt.serviceId],
                  consumed:
                    parseInt(
                      membershipData.selectedServices[appt.serviceId]
                        .consumed || 0
                    ) + 1,
                  reserved:
                    parseInt(
                      membershipData.selectedServices[appt.serviceId].reserved
                    ) - 1,
                };
                crud(
                  schState,
                  [
                    {
                      db: schState.db,
                      collection: "patients",
                      parameters: [
                        {pid: appt.pid},
                        {
                          $set: {
                            "memberships.$[membership].selectedServices":
                              membershipData.selectedServices,
                          },
                        },
                        {
                          arrayFilters: [
                            {"membership.uuid": appt.paidByMembership},
                          ],
                        },
                      ],
                      method: "updateOne",
                    },
                  ],
                  null
                );
                const updatedMemberships = (apptPatient.memberships || []).map(
                  (mData) => {
                    if (mData.uuid === appt.paidByMembership) {
                      return {
                        ...mData,
                        selectedServices: membershipData.selectedServices,
                      };
                    }
                    return mData;
                  }
                );
                dispatch({
                  type: "UPDATE_PATIENT",
                  pid: appt.pid,
                  payload: {
                    memberships: updatedMemberships,
                  },
                });
              }
              return (
                !appt?.cancelled &&
                changeAppointmentStatus(
                  appt,
                  "Arrived",
                  schState,
                  dispatch,
                  socket,
                  toast
                )
              );
            }}
          >
            Arrived &nbsp;
            <span className="w-[1.1rem] text-[1rem]">
              <FaWalking />
            </span>
          </div>
          {appt && status(appt) === "Unconfirmed" && (
            /*((status(appt) === "Unconfirmed" &&
              appt.createdBy.split("|")[1] !==
                (schState.doctor?.did || schState.admin?.id)) ||
              (status(appt) === "Rescheduled" &&
                appt.rescheduled.split("|")[1] !==
                  (schState.doctor?.did || schState.admin?.id)))*/ <span
              className="hover:cursor-pointer bg-[#5754FF] text-white text-xs px-2 py-1.5 overflow-hidden group rounded-lg flex justify-center items-center drop-shadow-lg"
              onClick={() => {
                return (
                  !appt?.cancelled &&
                  changeAppointmentStatus(
                    appt,
                    "Confirmed",
                    schState,
                    dispatch,
                    socket,
                    toast
                  )
                );
              }}
            >
              Confirm &nbsp;
              <img
                alt=""
                src="/images/Doctor/confirm_white.png"
                className="w-[1.1rem]"
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

function PatientInformation({appt, schState, dispatch, socket}) {
  const navigate = useNavigate();
  const [patient, setPatient] = useState(null);
  const [apptNumber, setApptNumber] = useState(null);

  useLayoutEffect(() => {
    if (appt) {
      if (schState.userType === "admin") {
        let patient = schState.doctorsPayload[appt["did"]].patients.find(
          (p) => p.pid === appt.pid
        );
        let n = schState.doctorsPayload[appt["did"]].appointments.filter(
          (a) => a.pid === appt.pid
        );
        setPatient(patient);
        setApptNumber(n.length);
      } else {
        let patient = schState.patients.find((p) => p.pid === appt.pid);
        let n = schState.appointments.filter((a) => a.pid === appt.pid);
        setPatient(patient);
        setApptNumber(n.length);
      }
    }
  }, [
    appt,
    schState.doctorsPayload,
    schState.patients,
    schState.appointments,
    schState.userType,
  ]);

  return (
    <div className="text-[#6271FF] space-y-1">
      {/*<div className="flex justify-between items-center">
        {patient?.intake && (
          <p className="flex items-center space-x-1 mt-1 ">
            <span className="text-[#6271FF]">
              <BsCheckCircleFill />
            </span>
            <span className="font-medium">&nbsp;Intake</span>
            <span className="font-bold">Complete</span>
          </p>
        )}
      </div>*/}

      {/*<p className="text-start text-sm italic">
        <b>{apptNumber}</b> Appointment(s) thus far
      </p>*/}
      {/*<p className="text-xs text-start whitespace-normal">
        &emsp;&emsp;{patient?.summary && patient?.summary.slice(0, 150) + "..."}
      </p>
      <p
        className="text-end text-xs font-medium hover:cursor-pointer"
        onClick={() =>
          navigate(`/patient/${patient.pid}`, {
            state: {
              ...schState,
            },
          })
        }
      >
        {patient?.summary && "Read more..."}
      </p>*/}
    </div>
  );
}

async function changeAppointmentStatus(
  appt,
  status,
  schState,
  dispatch,
  socket,
  toast
) {
  const restStatus = (status) => {
    switch (status) {
      case "No Show":
        return {cancelled: false, arrived: false};
      case "Cancelled":
        return {noShow: false, arrived: false};
      case "Arrived":
        return {noShow: false, cancelled: false};
      case "Confirmed":
        return {noShow: false, cancelled: false, arrived: false};
    }
  };

  let statusMap = {
    "No Show": "noShow",
    Cancelled: "cancelled",
    Arrived: "arrived",
    Confirmed: "pConfirmed",
    Rescheduled: "rescheduled",
  };

  if (appt?.aid && !appt[statusMap[status]]) {
    {
      const generatingToast = toast({
        title: "Saving status",
        description: "The status for this appointment is being updated.",
        status: "loading",
        variant: "subtle",
        duration: null,
        isClosable: true,
      });

      const updateBody = {
        ...appt,
        [statusMap[status]]: true,
        ...restStatus(status),
      };

      if (status === "Confirmed") {
        let note = updateBody.note || "";
        let name = schState.doctor ? schState.doctor.name : schState.admin.name;
        let date = lightFormat(new Date(), "dd/MM/yyyy h:mm aaa");
        note += `${note && "\n"}Confirmed by ${name} at ${date}.`;
        updateBody.note = note;
      }

      let {_id, ...data} = updateBody;
      let location = await crud(schState, [
        {
          db: schState.db,
          collection: "locations",
          parameters: [{lid: schState.selectedLocation}],
          method: "findOne",
        },
      ]);
      location = location.data[0];
      let sender = location?.name;

      crud(
        schState,
        [
          {
            db: schState.db,
            collection: "appointments",
            parameters: [{aid: data.aid}, {$set: data}],
            method: "updateOne",
          },
        ],
        {
          email: {
            type: "update_appointment",
            content: data,
            options: {status},
            sender,
            org: schState?.organization,
            location,
          },
        }
      )
        .then(async (res) => {
          dispatch({type: "UPDATE_APPOINTMENT", payload: updateBody});
          let {ntf, ntfList} = notificationsData(
            schState.userType,
            "Updated appointment",
            updateBody,
            schState
          );

          socket?.emit?.("update_appt", updateBody, {ntf});
          toast.close(generatingToast);

          let requestObjs = [
            {
              db: schState.db,
              collection: "notifications",
              parameters: [
                {userType: ntfList[0].userType},
                {$push: {[ntfList[0].id]: ntfList[0].notification}},
                {upsert: true},
              ],
              method: "findOneAndUpdate",
            },
            {
              db: schState.db,
              collection: "notifications",
              parameters: [
                {userType: ntfList[1].userType},
                {$push: {[ntfList[1].id]: ntfList[0].notification}},
                {upsert: true},
              ],
              method: "findOneAndUpdate",
            },
          ];
          await crud(schState, requestObjs);

          toast({
            title: "The appointment has been successfully updated!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
}
