import {
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  VStack,
  Box,
  Text,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import {EditIcon, DeleteIcon} from "@chakra-ui/icons";
import {AssignClinicianPopover} from "./AssignClinicianPopover";
import {crud} from "../../crudRequests";
import React from "react";

export const PatientCliniciansDialog = ({
  patient,
  clinicians,
  assignedClinicians,
  schState,
  dispatch,
}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const toast = useToast();
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const [clinicianToDelete, setClinicianToDelete] = React.useState(null);
  const cancelRef = React.useRef();

  const handleAssignClinician = async (clinician) => {
    try {
      dispatch({
        type: "UPDATE_PATIENT",
        pid: patient.pid,
        payload: {
          ...schState.patients.find((p) => p.pid === patient.pid),
          did: [...patient.did, clinician.value],
        },
      });
      await crud(schState, [
        {
          db: schState.db,
          collection: "patients",
          parameters: [
            {pid: patient.pid},
            {$set: {did: [...patient.did, clinician.value]}},
          ],
          method: "updateOne",
        },
      ]);

      onClose();
      toast({
        title: "Clinician Assigned",
        description:
          "The clinician has been successfully assigned to the patient.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to assign clinician. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteClinician = async (clinician) => {
    try {
      dispatch({
        type: "UPDATE_PATIENT",
        pid: patient.pid,
        payload: {
          ...schState.patients.find((p) => p.pid === patient.pid),
          did: patient.did.filter((did) => did && did !== clinician.did),
        },
      });
      await crud(schState, [
        {
          db: schState.db,
          collection: "patients",
          parameters: [
            {pid: patient.pid},
            {
              $set: {
                did: [
                  ...patient.did.filter((did) => did && did !== clinician.did),
                ],
              },
            },
          ],
          method: "updateOne",
        },
      ]);

      toast({
        title: "Clinician Removed",
        description:
          "The clinician has been successfully unassigned from the patient.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to unassign clinician. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const onAlertClose = () => {
    setIsAlertOpen(false);
    setClinicianToDelete(null);
  };

  const confirmDelete = () => {
    if (clinicianToDelete) {
      handleDeleteClinician(clinicianToDelete);
    }
    onAlertClose();
  };

  return (
    <>
      <IconButton
        icon={<EditIcon />}
        aria-label="Edit patient clinicians"
        onClick={onOpen}
        variant="outline"
        bg="blue.400"
        opacity="75%"
        boxShadow="lg"
        size="xs"
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxHeight="80vh" display="flex" flexDirection="column">
          <ModalHeader bg="gray.100" textColor="gray.600" fontSize="md">
            ASSIGNED
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY="auto" flex="1">
            <VStack
              spacing={2}
              align="stretch"
              divider={<Box height="1px" bg="gray.200" />}
            >
              {assignedClinicians.map((clinician, index) => (
                <Box
                  key={clinician.id || index}
                  p={2}
                  bg="white"
                  borderRadius="md"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text fontWeight="light">
                    {clinician.lastName}, {clinician.firstName}
                  </Text>
                  <IconButton
                    icon={<DeleteIcon />}
                    aria-label="Unassign clinician"
                    size="xs"
                    variant="solid"
                    colorScheme="red"
                    onClick={() => {
                      setClinicianToDelete(clinician);
                      setIsAlertOpen(true);
                    }}
                  />
                </Box>
              ))}
            </VStack>
          </ModalBody>

          <ModalFooter>
            <AssignClinicianPopover
              clinicians={clinicians}
              assignedClinicians={assignedClinicians}
              onAssign={handleAssignClinician}
              onDelete={handleDeleteClinician}
              schState={schState}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Unassign Clinician
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to unassign this clinician from the patient?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onAlertClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                Unassign
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
