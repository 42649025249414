import React, {useMemo, useState, useEffect, useRef} from "react";
import {
  loadDataForAdminLogin,
  loadDataForDoctorLogin,
} from "../../crudRequests";
import {useSearchParams} from "react-router-dom";
import {
  Flex,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Box,
  Tbody,
  TableCaption,
  Input,
  Text,
  InputGroup,
  InputLeftElement,
  Icon,
  Badge,
  useToast,
} from "@chakra-ui/react";
import {IoSearch} from "react-icons/io5";
import {
  PhoneIcon,
  EmailIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ArrowUpDownIcon,
} from "@chakra-ui/icons";
import CliniciansByPatient from "./CliniciansByPatient";
import AddPatient from "./AddPatient";
import renderTextByAccess from "../../utils/renderTextByAccess";
import verifyAccess from "../../utils/verifyAccess";
import renderBlurredText from "../../utils/renderBlurredText";
import PaginationBar from "./PaginationBar";
import {patientsFilter} from "./helpers/patientsFilter";
import {getAge} from "./helpers/getAge";
import {getLastVisit} from "./helpers/getLastVisit";
import {getNextVisit} from "./helpers/getNextVisit";
import {fetchPatient, cancelAction} from "./helpers/fetchPatient";
import {SortableColumnHeader} from "../SortableColumnHeader";

export default function PatientsTable({
  dispatch,
  dashState,
  setPatient,
  navigate,
  setPatientQueryParams,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast();
  const [searching, setSearching] = useState(false);
  const [patients, setPatients] = useState(dashState.patients);
  const searchTextRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [skip, setSkip] = useState(0);
  const [count, setCount] = useState(0);
  const [reset, setReset] = useState({});
  const [sort, setSort] = useState({column: "fullName", direction: "asc"});
  const [sortedPatients, setSortedPatients] = useState([]);
  const reloadingDataRef = useRef(null);
  let appointments = dashState.appointments;

  const cliniciansMap = useMemo(() => {
    let clins = dashState.doctor ? [dashState.doctor] : dashState.doctors;
    return clins.reduce((acc, ele) => {
      acc[ele.did] = ele;
      return acc;
    }, {});
  }, [dashState.doctor, dashState.doctors]);

  const reloadState = function (destination, abortController, action) {
    action?.();
    console.log("reloading state");
    if (dashState.userType === "admin")
      loadDataForAdminLogin(
        {
          ...dashState,
          destination,
        },
        navigate,
        null,
        abortController.signal
      );
    else
      loadDataForDoctorLogin(
        {
          ...dashState,
          destination,
        },
        navigate,
        null,
        abortController.signal
      );
  };

  reloadingDataRef.current = dashState.changingLocation;

  useEffect(() => {
    const abortController = new AbortController();
    if (!dashState?.firstLoading) {
      reloadingDataRef.current = true;
      reloadState("../patients", abortController, () => {
        dispatch({type: "CHANGING_LOCATION_LOADER", payload: true});
      });

      return () => {
        abortController.abort();
        dispatch({type: "CHANGING_LOCATION_LOADER", payload: false});
        reloadingDataRef.current = false;
      };
    }
  }, [dashState.selectedLocation]);

  /*useEffect(() => {
    if (searchParams.get("query")) {
      fetchPatient(dashState, searchParams.get("query"), setPatient, toast);
      let newParams = {};
      searchParams.get("aid") && (newParams["aid"] = searchParams.get("aid"));
      searchParams.get("tab") && (newParams["tab"] = searchParams.get("tab"));
      setPatientQueryParams(newParams);
    }
  }, []);*/

  const memoedPatientBlurredData = useMemo(() => {
    const map = {};
    patients.forEach((pt) => {
      map[pt.pid] = {
        fName: renderTextByAccess(dashState, pt, "fName"),
        lName: renderTextByAccess(dashState, pt, "lName"),
        age: renderTextByAccess(dashState, pt, "age"),
        email: renderTextByAccess(dashState, pt, "email"),
        pt,
      };
    });
    return map;
  }, [dashState.patients]);

  useEffect(() => {
    if (!reloadingDataRef.current) {
      const sortedPatientsCopy = dashState.patients.map((patient) => {
        const getStatus = () => {
          if (patient.deleted) {
            return {label: "Recently deleted", color: "black"};
          }
          if (patient.deceased) {
            return {label: "Deceased", color: "red"};
          }
          if (patient.inactive) {
            return {label: "Inactive", color: "orange"};
          }
          return {label: "Active", color: "blue"};
        };
        const patientStatus = getStatus();
        return {
          fullName: `${memoedPatientBlurredData[patient.pid]?.["lName"]}, ${
            memoedPatientBlurredData[patient.pid]?.["fName"]
          }`,
          pt: memoedPatientBlurredData[patient.pid]?.pt,
          statusBadgeColor: patientStatus.color,
          statusBadgeLabel: patientStatus.label,
          visitInformationLastSortText: getLastVisit(patient, appointments)
            .sortText,
          visitInformationLastComponent: getLastVisit(patient, appointments)
            .component,
          visitInformationNextSortText: getNextVisit(patient, appointments)
            .sortText,
          visitInformationNextComponent: getNextVisit(patient, appointments)
            .component,
          visitInformationNext: getNextVisit(patient, appointments),
          thirdPartyPayerNames: Array.isArray(patient?.tpp)
            ? patient.tpp
                .map((payer) =>
                  dashState.insurers.find(
                    (insurer) => insurer.iid === payer?.insurerId
                  )
                )
                .filter((insurer) => insurer !== undefined)
                .map((insurer) => insurer.name)
                .join(", ")
            : "N/A",
          ...patient,
        };
      });
      sortedPatientsCopy.sort((a, b) => {
        if (
          [
            "visitInformationLastSortText",
            "visitInformationNextSortText",
          ].includes(sort.column)
        ) {
          if (sort.direction === "asc") {
            return b[sort.column] - a[sort.column];
          } else {
            return a[sort.column] - b[sort.column];
          }
        }

        try {
          if (sort.direction === "asc") {
            return (a[sort.column] || "").localeCompare(b[sort.column] || "");
          } else {
            return (b[sort.column] || "").localeCompare(a[sort.column] || "");
          }
        } catch (e) {}
      });
      setSearching(true);
      let request = () => {
        patientsFilter({
          searchText,
          limit: 30,
          skip,
          patients: sortedPatientsCopy.filter((patient) => !patient.deleted),
          selectedDoctor: dashState.selectedDoctor,
        })
          .then((pts) => {
            //remove deleted patients here

            if (Math.ceil(pts.count / 30) <= skip) {
              setSkip(0);
              setReset({});
            }
            setSearching(false);
            setPatients(pts.data);
            setCount(pts.count);
          })
          .catch((e) => {
            setSearching(false);
            console.log(e);
          });
      };

      let id = setTimeout(() => {
        request();
      }, 1000);

      return () => {
        clearTimeout(id);
      };
    }
  }, [
    dashState.selectedDoctor?.did,
    dashState.selectedDoctor,
    searchText,
    skip,
    dashState.patients,
    sort.column,
    sort.direction,
  ]);

  useEffect(() => {
    if (dashState?.firstLoading)
      navigate("../patients", {
        state: {
          ...dashState,
          changingLocation: reloadingDataRef.current,
          firstLoading: false,
        },
      });
  }, []);

  return (
    <>
      <Flex p="4" gap="8">
        <AddPatient dispatch={dispatch} setPatient={setPatient} />
        <InputGroup>
          <InputLeftElement>
            <Icon as={IoSearch} color="gray.500" />
          </InputLeftElement>
          <Input
            type="text"
            ml="-1"
            placeholder="Search patients"
            ref={searchTextRef}
            onChange={(e) => setSearchText(e.target.value.toLowerCase())}
          />
        </InputGroup>
      </Flex>
      <TableContainer
        maxW="8xl"
        flex="1"
        borderColor="gray.50"
        borderRadius="md"
        boxShadow="md"
        overflowY={"scroll"}
      >
        <Table size="md">
          <Thead bg="gray.100">
            <Tr>
              <SortableColumnHeader
                label="Name"
                currentSort={sort}
                column="fullName"
                onSort={setSort}
              />
              <SortableColumnHeader
                label="Status"
                currentSort={sort}
                column="statusBadgeLabel"
                onSort={setSort}
              />
              <SortableColumnHeader
                label="Last Visit"
                currentSort={sort}
                column="visitInformationLastSortText"
                onSort={setSort}
              />
              <SortableColumnHeader
                label="Next Visit"
                currentSort={sort}
                column="visitInformationNextSortText"
                onSort={setSort}
              />
              <Th>CLINICIAN</Th>

              <SortableColumnHeader
                label="Third Party Payer"
                currentSort={sort}
                column="thirdPartyPayerNames"
                onSort={setSort}
              />
              <Th>
                <Text>Contact</Text>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {patients.map((patient, i) => {
              return (
                <Tr key={i}>
                  <Td
                    maxW="250px"
                    fontSize={"15px"}
                    cursor={"pointer"}
                    onClick={() =>
                      fetchPatient(dashState, patient.pid, setPatient, toast)
                    }
                  >
                    <Text fontWeight={"semibold"}>
                      {memoedPatientBlurredData[patient.pid]?.["lName"]}
                      {", "}
                      {memoedPatientBlurredData[patient.pid]?.["fName"]}
                    </Text>
                    <Text fontSize={"11px"}>
                      {verifyAccess(dashState, "dob")
                        ? patient.dob === "" || !patient.dob
                          ? "unknown "
                          : getAge(patient.dob) + " y/o "
                        : renderBlurredText("00 y/o ", true)}{" "}
                      {patient.gender || ""}
                    </Text>
                  </Td>

                  <Td fontSize={"15px"}>
                    <Badge
                      colorScheme={patient.statusBadgeColor}
                      rounded="md"
                      px="2"
                      py="0.5"
                    >
                      {patient.statusBadgeLabel}
                    </Badge>
                  </Td>
                  <Td fontSize={"15px"}>
                    {patient.visitInformationLastComponent}
                  </Td>
                  <Td fontSize={"15px"}>
                    {patient.visitInformationNextComponent}
                  </Td>
                  <Td fontSize={"15px"}>
                    {
                      <CliniciansByPatient
                        patient={patient}
                        assignedClinicians={[]
                          .concat(patient.did)
                          .filter((e) => cliniciansMap[e])
                          .map((e) => cliniciansMap[e])}
                        clinicians={dashState.doctors}
                        isAdmin={dashState.userType === "admin"}
                        schState={dashState}
                        dispatch={dispatch}
                      />
                    }
                  </Td>
                  <Td fontSize={"15px"}>{patient.thirdPartyPayerNames}</Td>
                  <Td fontSize={"15px"}>
                    <Icon
                      aria-label="phone"
                      as={PhoneIcon}
                      mx="2"
                      color="gray.600"
                      cursor={"pointer"}
                      fontSize={"18px"}
                      onClick={() =>
                        verifyAccess(dashState, "phone")
                          ? (window.location.href = `tel:` + patient["phone"])
                          : null
                      }
                    />
                    <Icon
                      aria-label="email"
                      mx="2"
                      color="gray.600"
                      cursor={"pointer"}
                      fontSize={"18px"}
                      as={EmailIcon}
                      onClick={() =>
                        verifyAccess(dashState, "email")
                          ? (window.location.href =
                              `mailto:` + patient["email"])
                          : null
                      }
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
          {searching && <TableCaption>Loading patients...</TableCaption>}
          {patients.length == 0 && !searching && (
            <TableCaption>No patients to display.</TableCaption>
          )}
        </Table>

        <PaginationBar {...{skip, setSkip, count, searching, reset}} />
      </TableContainer>
    </>
  );
}
