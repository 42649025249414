import {
  Avatar,
  Text,
  Flex,
  VStack,
  useToast,
  Card,
  CardHeader,
  CardBody,
  Heading,
  IconButton,
} from "@chakra-ui/react";
import {lightFormat} from "date-fns";
import {EditIcon, DeleteIcon} from "@chakra-ui/icons";
import {useMemo} from "react";
import {deleteNote} from "./helpers/deleteNote";
export default function Note({
  setNotes,
  dispatch,
  state,
  note,
  setPreview,
  setSelectedNote,
  cliniciansMap,
  adminsMap,
  onOpen,
}) {
  let user = useMemo(() => {
    switch (note.userType) {
      case "clinician":
        return cliniciansMap[note.userId];
      case "admin":
        return adminsMap[note.userId];

      default:
        return cliniciansMap[note.userId];
    }
  }, [note]);
  const toast = useToast();

  return (
    <Card maxW="md" bg="gray.50">
      <CardHeader>
        <Flex spacing="4" justify={"space-between"} align={"flex-start"}>
          <Flex gap="2" alignItems="center">
            <Avatar showBorder size="md" name={user?.name} src={user?.photo} />

            <VStack textAlign={"start"} align={"self-start"} gap={0}>
              <Heading size="xs">{user?.name}</Heading>
              <Text fontSize={"xs"}>{user?.title}</Text>
            </VStack>
          </Flex>
          <Flex direction="column" alignItems="flex-end">
            <Flex
              gap="1"
              fontSize={"xs"}
              color={"gray.500"}
              alignItems="center"
              flexWrap="wrap"
              display={{
                base: "none",
                md: "flex",
              }}
            >
              <Text>{"Created at:"}</Text>
              <Text color={"gray.700"} fontWeight={500}>
                {note?.createdAt
                  ? lightFormat(note?.createdAt, "dd/MM/yyyy hh:mm aaa")
                  : "-"}
              </Text>
            </Flex>
            <Flex mt={2} gap={2}>
              <IconButton
                size={"xs"}
                aria-label="Edit note"
                icon={<EditIcon />}
                variant="solid"
                colorScheme="blue"
                onClick={() => {
                  onOpen();
                  setSelectedNote(note);
                }}
              />
              <IconButton
                size={"xs"}
                aria-label="Delete note"
                icon={<DeleteIcon />}
                variant="solid"
                colorScheme="red"
                onClick={() => deleteNote(note, state, setNotes, toast)}
              />
            </Flex>
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody pt="0">
        <VStack align={"self-start"}>
          <Heading color={"blue.500"} size="sm" textAlign={"start"}>
            {note.title}
          </Heading>
          <Text w="full" fontSize="sm" textAlign={"start"}>
            {note.description}
          </Text>
        </VStack>
      </CardBody>
    </Card>
  );
}
