import React, {useState, useMemo, useRef, useEffect, useContext} from "react";
import {crud} from "../crudRequests";
import {lightFormat} from "date-fns";

import {TiShoppingCart} from "react-icons/ti";
import {PatientContext} from "../pages/Patients";
import {PiWarningBold} from "react-icons/pi";

import {
  fullInvoice,
  patientInvoice,
  insurerInvoice,
} from "./appointments/payment/uploads";
import {
  appointmentAvailable,
  generateAppointmentsIntervals,
} from "../additional_files/helpers";
import {v4 as uuidv4} from "uuid";
import {setToCurrentWeek} from "../additional_files/unavailable";
import swal from "sweetalert";
import {PiArrowCircleDownFill} from "react-icons/pi";
import {notificationsData} from "../additional_files/notifications";
import {months, weekday} from "../additional_files/helpers";
import SelectedProducts from "./Products/SelectedProducts";
import {SocketContext} from "../additional_files/context";
import renderTextByAccess from "../utils/renderTextByAccess";
import verifyAccess from "../utils/verifyAccess";
import DefineApptServiceCriteria from "./DefineApptServiceCriteria";
import {
  Button,
  useToast,
  Text,
  FormControl,
  FormLabel,
  Switch,
} from "@chakra-ui/react";

import ExternalSales from "./Products/ExternalSales";
import {AssignedProfessional} from "./appointments/boockAppointment/helpers/AssignedProfessional";
import Recurrent from "./appointments/boockAppointment/recurrent";
import createRecurrence from "./appointments/boockAppointment/recurrent/helpers/createRecurrence";
import fetchProducts from "./Products/helpers/fetchProducts";

export default function BookAppointment(props) {
  const {
    schState,
    stateChanger,
    dispatch,
    patient: currentPatient,
    defaultData,
  } = props;
  const insurersMap = useMemo(() => {
    if (schState?.insurers.length > 0) {
      return schState?.insurers.reduce((ac, e) => {
        ac[e.iid] = e.name || "";
        return ac;
      }, {});
    }
    return {};
  }, [schState?.insurers]);

  const providerTypesMap = useMemo(() => {
    if (schState?.providerTypes.length > 0) {
      return schState?.providerTypes.reduce((ac, e) => {
        ac[e.typeId] = e.name || "";
        return ac;
      }, {});
    }
    return {};
  }, [schState?.providerTypes]);

  const [products, setProducts] = useState([]);
  const [location, setLocation] = useState(null);
  const [address, setAddress] = useState(null);

  const [showSaleModal, setShowSaleModal] = useState(false);
  const [productSalesInformation, setProductSalesInformation] = useState({
    products: [],
    tax: 0,
    amount: 0,
  });
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [customDuration, setCustomDuration] = useState(false);
  const [confirmationMeans, setConfirmationMeans] = useState({
    text: true,
    email: true,
  });

  useEffect(() => {
    fetchProducts(schState, setLoadingProducts, setProducts, toast);
  }, []);

  useEffect(() => {
    fetchProducts(schState, setLoadingProducts, setProducts, toast);
  }, []);

  const [selectedDate, setSelectedDate] = useState(
    defaultData?.date
      ? lightFormat(defaultData.date, "yyyy-MM-dd")
      : lightFormat(new Date(), "yyyy-MM-dd")
  );

  const toast = useToast();
  const searchRef = useRef(null);
  const bookServiceRef = useRef(null);
  const bookDateRef = useRef(null);
  const bookTimeRef = useRef(null);

  const bookTypeRef = useRef(null);
  const bookNoteRef = useRef(null);
  const bookError = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const bookSuccess = useRef(null);
  const clickRef = useRef(true);
  const notifySelectionsRef = useRef(new Set([1, 24]));
  const recurrentCriteriaRef = useRef({});

  const appointmentsIntervals = useRef(null);
  const unavailableDoctorIntervals = useRef(null);
  const [doctor, setDoctor] = useState(
    defaultData?.doctor || schState.selectedDoctor
  );
  const [recurrent, setRecurrent] = useState(false);

  const [bookingMedia, setBookingMedia] = useState({
    "in-person": true,
    phone: false,
  });
  const [appointmentType, setAppointmentType] = useState("teleconference");
  const [assignedProfessional, setAssignedProfessional] = useState({
    clinician: true,
    technician: false,
  });

  const [technician, setTechnician] = useState("");

  const [firstTime, setFirstTime] = useState(false);
  const supervisorRef = useRef(defaultData?.doctor || schState?.selectedDoctor);

  let supervisor = supervisorRef.current;

  const [selectdServiceIndx, setSelectedServiceIndx] = useState();
  const [services, setServices] = useState(() => {
    let s = new Set(doctor?.services || []);
    return schState?.services.filter(
      (e) => s.has(e.serviceId) && !e["non-sessions"]
    );
  });

  const [patient, setPatient] = useState(
    currentPatient || schState.selectedPatient
  );

  const [duration, setDuration] = useState("15 min");

  const socket = useContext(SocketContext);
  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState("");

  const [creating, setCreating] = useState(false);
  const criteriaKeyRef = useRef(null);
  const [serviceCriteriaKey, setServiceCriteriaKey] = useState("");
  let service = services[selectdServiceIndx];

  let criteria = useMemo(() => {
    let crt = schState.serviceCriteria.filter((c) => c.type !== "Insurers");

    return crt.concat([
      {type: "Insurers", list: schState.insurers},
      {type: "Clinicians", list: schState?.doctors || [schState?.doctor]},
      {type: "Provider Types", list: schState.providerTypes},
    ]);
  }, [schState.serviceCriteria, schState.insurers, schState.doctors]);

  let initialCriteria = useMemo(() => {
    let initialCriteriaIdValues = {};
    let initialCriteriaValues = {};

    let tpp = "";

    if (Array.isArray(patient?.tpp)) {
      let insr = patient?.tpp.find(
        (ins) => parseInt(ins.noOfInsuredSessions ?? 0) > 0
      );

      insr =
        insr ||
        patient?.tpp.find((ins) => ins.noOfInsuredSessions === undefined);

      tpp = insr ? insr.insurerId : "";
    }

    if (tpp) {
      initialCriteriaIdValues["Insurers"] = tpp;
      initialCriteriaValues["Insurers"] = insurersMap[tpp] || "";
    }

    if (doctor) {
      initialCriteriaIdValues["Clinicians"] = doctor.did;
      initialCriteriaValues["Clinicians"] = doctor.name;
    }

    if (doctor && providerTypesMap[doctor.providerType]) {
      initialCriteriaIdValues["Provider Types"] = doctor.providerType;
      initialCriteriaValues["Provider Types"] =
        providerTypesMap[doctor.providerType];
    }

    let initialSelectedCriteria = criteria.filter((e) => {
      return (
        (doctor && e.type === "Clinicians") ||
        (doctor &&
          providerTypesMap[doctor.providerType] &&
          e.type === "Provider Types") ||
        (tpp && insurersMap[tpp] && e.type === "Insurers")
      );
    });

    return {
      initialCriteriaIdValues,
      initialCriteriaValues,
      initialSelectedCriteria,
    };
  }, [patient, patient?.tpp, doctor, criteria]);

  function criteriaFormatKey(data) {
    let en = Object.entries(data).filter(([k, v]) => v !== undefined);
    if (en.length > 0) {
      en = en.map((el) => el.join("|"));
      en.sort();
      return en.join("||");
    } else return "";
  }

  useEffect(() => {
    setServices(() => {
      let s = new Set(doctor?.services || []);
      return schState?.services.filter(
        (e) => s.has(e.serviceId) && !e["non-sessions"]
      );
    });
    setSelectedServiceIndx("");
  }, [schState?.services, doctor]);

  useEffect(() => {
    if (schState.userType === "admin") {
      let {doctorsPayload} = schState;
      if (doctor) {
        let docAppointments = doctorsPayload[doctor.did].appointments;
        unavailableDoctorIntervals.current =
          doctorsPayload[doctor.did].unavailable?.intervals;
        appointmentsIntervals.current =
          generateAppointmentsIntervals(docAppointments);
      }
    } else {
      let docAppointments = schState.appointments;
      appointmentsIntervals.current =
        generateAppointmentsIntervals(docAppointments);
      unavailableDoctorIntervals.current =
        schState.selectedDoctor.unavailable?.intervals;
    }
  }, [schState, doctor]);

  useEffect(() => {
    getRoomsAndLocation();
  }, [schState.selectedLocation]);

  function getRoomsAndLocation() {
    let req = [
      {
        db: schState.db,
        collection: "rooms",
        parameters: [{lid: schState.selectedLocation}],
        method: "find",
      },
      {
        db: schState.db,
        collection: "locations",
        parameters: [{lid: schState.selectedLocation}],
        method: "findOne",
      },
    ];

    crud(schState, req).then((res) => {
      setRooms(res?.data[0] || []);
      setLocation(res?.data[1] || null);
    });
  }

  const patientTppInfo = useMemo(() => {
    if (Array.isArray(patient?.tpp)) {
      let tpp = criteriaKeyRef.current?.["Insurers"] || false;
      return patient?.tpp.find((ins) => ins.insurerId === tpp);
    }
    return null;
  }, [serviceCriteriaKey, patient]);

  async function bookAppointment(availableMemberships) {
    setCreating(true);
    setErrorMessage("");
    const aid = uuidv4();
    const invoiceId = id(8);
    const receiptId = id(8);
    if (
      bookServiceRef.current.value === "" ||
      (!doctor && assignedProfessional["clinician"]) ||
      (technician.trim() === "" && assignedProfessional["technician"]) ||
      !patient ||
      !supervisor
    ) {
      setErrorMessage(
        "Please select a patient, clinician/technician, supervisor and service from the lists to proceed to book an appointment for them."
      );
      setCreating(false);
    } else {
      const inputTime = bookTimeRef.current.value;
      const time =
        parseInt(inputTime.split(":")[0]) > 12
          ? (parseInt(inputTime.split(":")[0]) - 12).toString() +
            ":" +
            inputTime.split(":")[1] +
            " PM"
          : parseInt(inputTime.split(":")[0]).toString() === "0"
          ? "12:" + inputTime.split(":")[1] + " AM"
          : parseInt(inputTime.split(":")[0]).toString() === "12"
          ? "12:" + inputTime.split(":")[1] + " PM"
          : parseInt(inputTime.split(":")[0]).toString() +
            ":" +
            inputTime.split(":")[1] +
            " AM";

      let dateArray = selectedDate.split("-").map((e) => parseInt(e));
      let h_min = inputTime.split(":").map((e) => parseInt(e));
      dateArray[1]--;
      const inputDate = new Date(...dateArray);
      const ISOdate = new Date(...dateArray.concat(h_min)).getTime();
      const date =
        weekday[inputDate.getDay()] +
        ", " +
        months[inputDate.getMonth()] +
        " " +
        inputDate.getDate() +
        ", " +
        inputDate.getFullYear();

      let serviceCriteriaKey = criteriaFormatKey(criteriaKeyRef.current);
      let service = services[bookServiceRef.current.value];
      const availableInMembership = availableMemberships?.find((membData) => {
        const serviceInfo = membData.selectedServices[service.serviceId];
        if (
          serviceInfo &&
          serviceInfo.quantity -
            (serviceInfo.consumed || 0) -
            (serviceInfo.reserved || 0) >
            0
        ) {
          return true;
        }
        return false;
      });
      let costsByCriteria = service.defaultCost || 100;

      if (serviceCriteriaKey && !service?.isfixedCost)
        costsByCriteria =
          service.costsByCriteria?.[serviceCriteriaKey] ?? costsByCriteria;

      let hours = Number.parseFloat(duration.split(" ")[0]) / 60;
      let amount = Number.parseFloat(
        (service?.isfixedCost ? 1 : hours) * Number.parseFloat(costsByCriteria)
      ).toFixed(2);

      let tax = 0;
      if (service?.taxable === true) {
        let typeId = criteriaKeyRef.current?.["Provider Types"];

        if (typeId) {
          let providerType = schState.providerTypes.find(
            (t) => t.typeId === typeId
          );
          tax = parseFloat(providerType?.tax || 0);
        }
      }

      amount = (amount * (1 + tax / 100)).toFixed(2);

      let serviceAmount = amount;
      if (availableInMembership) {
        serviceAmount = 0;
        amount = 0;
      }
      if (productSalesInformation?.products?.length > 0) {
        let productAmount = parseFloat(productSalesInformation?.amount || 0);
        amount = parseFloat(amount) + parseFloat(productAmount);
      }

      let tpp = criteriaKeyRef.current?.["Insurers"] || false;
      let tppAmount = tpp && !availableInMembership ? amount : 0;

      if (
        patientTppInfo &&
        !availableInMembership &&
        !isNaN(patientTppInfo.sessionDeductionAmount) &&
        parseFloat(patientTppInfo.sessionDeductionAmount) >= 0
      ) {
        tppAmount = parseFloat(patientTppInfo.sessionDeductionAmount);
      }

      let patientAmount = Math.max(amount - tppAmount, 0);

      const roomName = aid;

      let supervisorId = supervisor?.did;
      let supervisorName = supervisor?.name || schState.selectedDoctor?.name;
      let supervisorEmail = supervisor?.email || schState.selectedDoctor?.email;

      let locationName =
        schState.locations?.find((l) => l.lid === schState.selectedLocation)
          ?.name || "";
      const payload = {
        aid: aid,
        invoiceId,
        receiptId,
        confirmationMeans,
        room: selectedRoom,
        roomName: rooms.find((r) => r.rid === selectedRoom)?.roomName || "",
        lid: schState.selectedLocation,
        db: schState.db,
        locationName,
        pid: patient.pid,
        pName: patient.fName + " " + patient.lName,
        pEmail: patient.email,
        pPhone: patient.phone,
        pConfirmed: false,
        did: assignedProfessional["clinician"] ? doctor.did : supervisorId,
        oid: assignedProfessional["clinician"] ? doctor.oid : supervisor?.oid,
        dName: assignedProfessional["clinician"]
          ? doctor.name || schState.selectedDoctor.name
          : supervisorName,
        dEmail: assignedProfessional["clinician"]
          ? doctor.email || schState.selectedDoctor.email
          : supervisorEmail,
        supervisorId,
        supervisorName,
        supervisorEmail,
        teleconference: appointmentType === "teleconference",
        telephone: appointmentType === "telephone",
        appointmentType,
        address,
        // patientApptLink: `https://meet.brightlight.ai?room=${aid}&name=${patient.fName}&email=${patient.email}`,
        patientApptLink: `https://teleconference.brightlight.ai/${encodeURIComponent(
          roomName
        )}`,
        clinicApptLink: `https://teleconference.brightlight.ai/${encodeURIComponent(
          roomName
        )}`,
        date: date,
        time: time,
        duration,
        note: bookNoteRef.current.value,
        summary: "",
        cancelled: false,
        noShow: false,
        service: services[selectdServiceIndx]?.serviceName,
        serviceDescription: services[selectdServiceIndx]?.description || "",
        serviceId: services[selectdServiceIndx]?.serviceId,
        serviceCodes: services[selectdServiceIndx]?.serviceCodes || [],
        ISOdate,
        createdBy: `${schState.userType}|${
          schState.admin?.id || schState.doctor?.did
        }`,
        paymentStatus:
          availableInMembership && !products.length ? "paid" : "pending",
        notifyBefore: [...notifySelectionsRef.current],
        serviceCriteriaKey,
        claim: false,
        serviceTax: parseFloat(tax),
        serviceAmount,
        amount,
        products: productSalesInformation?.products,
        productTax: parseFloat(productSalesInformation?.tax || 0),
        productAmount: parseFloat(productSalesInformation?.amount || 0),
        tpp,
        tppAmount,
        patientAmount,
        amountPaidByPatient: 0,
        amountPaidByTpp: 0,
        tppPaymentStatus: tppAmount > 0 ? "pending" : "paid",
        patientPaymentStatus: patientAmount > 0 ? "pending" : "paid",
        apptCreatedBy: schState.userType === "doctor" ? "clinician" : "admin",
        apptBookingMedia: bookingMedia["in-person"] ? "in-person" : "phone",
        firstTime,
        assignedProfessional: assignedProfessional["clinician"]
          ? "clinician"
          : "technician",
        technician: assignedProfessional["technician"] ? technician.trim() : "",
        paidByMembership: availableInMembership?.uuid,
      };

      let sender = location?.name;
      let transaction = {
        type: "appointment",
        tid: uuidv4(),
        invoiceId: payload.invoiceId,
        receiptId: payload.receiptId,
        aid: payload.aid,
        oid: payload.oid,
        lid: payload.lid,
        db: schState.db,
        locationName,
        pid: patient.pid,
        pName: patient.fName + " " + patient.lName,
        pEmail: patient.email,
        did: payload.did,
        dName: payload.dName,
        dEmail: payload.dEmail,
        time: payload.duration,
        claim: payload.claim,
        supervisorId: payload.supervisorId,
        supervisorName: payload.supervisorName,
        amount,
        serviceAmount,
        serviceTax: payload.serviceTax,
        products: productSalesInformation?.products,
        productTax: parseFloat(productSalesInformation?.tax || 0),
        productAmount: parseFloat(productSalesInformation?.amount || 0),
        paid: 0,
        pending: true,
        cancelled: false,
        serviceId: payload.serviceId,
        serviceType: payload.service.trim(),
        serviceDescription: payload.serviceDescription,
        serviceDate: payload.ISOdate,
        insurance: patient.insurance,
        dgCodes: [],
        serviceCodes: payload.serviceCodes,
        billingCodes: [],
        tpp,
        tppAmount,
        patientAmount,
        amountPaidByPatient: 0,
        amountPaidByTpp: 0,
        tppPaymentStatus: tppAmount > 0 ? "pending" : "paid",
        patientPaymentStatus: patientAmount > 0 ? "pending" : "paid",
        paymentStatus: availableInMembership ? "paid" : "pending",
        assignedProfessional: assignedProfessional["clinician"]
          ? "clinician"
          : "technician",
        technician: payload.technician,
        paidByMembership: availableInMembership?.uuid,
        date: `${lightFormat(new Date(), "dd/MM/yy HH:mm")}`
      };

      if (recurrent) {
        try {
          setCreating(true);
          clickRef.current = false;
          await createRecurrence({
            recurrence: recurrentCriteriaRef.current,
            dispatch,
            state: schState,
            apptPayload: payload,
            transaction,
            doctor,
            patient,
            location,
            sender,
          });

          setCreating(false);
          clickRef.current = true;
          stateChanger(false);
          toast({
            title: "The appointments has been successfully booked!",
            status: "success",
            duration: 4000,
            isClosable: true,
          });
        } catch (error) {
          toast({
            title: "Bookikg error.",
            description: error.message,
            status: "error",
            isClosable: true,
          });
          clickRef.current = true;
          setCreating(false);
        }

        return;
      }

      let {pid, did, lid, oid} = payload;
      let socketRooms = [pid, did, lid + oid];

      let sendInvEmail = async (schState, transaction) => {
        try {
          let invData = {
            state: schState,
            transaction,
            doctor,
            patient,
            tpp: transaction.tpp
              ? schState?.insurers.find(
                  (insurer) => insurer.iid === transaction.tpp
                )
              : null,
            supervisor,
          };
          if (transaction.tpp) await insurerInvoice(invData);
          await patientInvoice(invData);
          await fullInvoice(invData);
        } catch (error) {
          console.log(error);
          toast({
            title: "Bookikg error.",
            description: error.message,
            status: "error",
            isClosable: true,
          });
        }
      };

      const updateInsuredSessions = () => {
        if (Array.isArray(patient?.tpp)) {
          let insr = patient?.tpp.find((ins) => ins.insurerId === tpp);

          if (insr && insr.noOfInsuredSessions) {
            let noOfInsuredSessions = parseInt(insr.noOfInsuredSessions) - 1;

            let newPatientTpp = patient?.tpp.map((ins) =>
              ins.insurerId === tpp ? {...ins, noOfInsuredSessions} : ins
            );

            crud(
              schState,
              [
                {
                  db: schState.db,
                  collection: "patients",
                  parameters: [
                    {pid: payload.pid, "tpp.insurerId": tpp},
                    {$set: {"tpp.$.noOfInsuredSessions": noOfInsuredSessions}},
                  ],
                  method: "updateOne",
                },
              ],
              {
                socket: {
                  rooms: socketRooms,
                  arguments: [
                    "update_pt",
                    payload.pid,
                    {tpp: newPatientTpp},
                    {},
                    [payload.did, payload.lid + payload.oid, payload.pid],
                  ],
                },
              }
            ).then(() => {
              dispatch({
                type: "UPDATE_PATIENT",
                pid: payload.pid,
                payload: {tpp: newPatientTpp},
                options: null,
              });
            });
          }
        }
      };

      let action = () => {
        clickRef.current = false;

        let userType = schState.userType;
        let {ntf, ntfList} = notificationsData(
          userType,
          "New appointment",
          payload,
          schState
        );

        let pt = patient;

        let emailOption = {
          type: "new_appointment",
          content: payload,
          sender,
          org: schState.organization,
          location,
        };
        let smsOption = {
          sender,
          to: patient.phone,
          sms:
            "Dear " +
            payload.pName +
            " , A new appointment has been scheduled with " +
            payload.dName +
            " for " +
            new Date(payload.ISOdate).toString().replace(/\(.+\)/g, "") +
            `. Sincerely, ${sender}.`,
        };

        let requestObjs = [
          {
            db: schState.db,
            collection: "appointments",
            parameters: [payload],
            method: "insertOne",
          },
          {
            db: schState.db,
            collection: "patients",
            parameters: [{pid: patient.pid}, {$set: {firstTimeAppt: false}}],
            method: "updateOne",
          },
          {
            db: schState.db,
            collection: "notifications",
            parameters: [
              {userType: ntfList[0].userType},
              {$push: {[ntfList[0].id]: ntfList[0].notification}},
              {upsert: true},
            ],
            method: "findOneAndUpdate",
          },
          {
            db: schState.db,
            collection: "notifications",
            parameters: [
              {userType: ntfList[1].userType},
              {$push: {[ntfList[1].id]: ntfList[1].notification}},
              {upsert: true},
            ],
            method: "findOneAndUpdate",
          },
          {
            db: schState.db,
            collection: "billing",
            parameters: [transaction],
            method: "insertOne",
          },
        ];

        if (Array.isArray(pt.did)) {
          if (!pt.did.includes(doctor.did) && pt.did.length > 0) {
            swal({
              title: "Confirm new clinician!",
              text: `${payload.pName} is already a patient of another doctor. Are you sure he wants to make an appointment with ${payload.dName}?`,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((value) => {
              if (value) {
                requestObjs.push({
                  db: schState.db,
                  collection: "patients",
                  parameters: [
                    {pid: pt.pid},
                    {$set: {did: [...pt.did, doctor.did]}},
                  ],
                  method: "updateOne",
                });
                crud(schState, requestObjs, {
                  email: emailOption,
                  sms: smsOption,
                  // verifyCard: {pid: payload.pid, db: schState.db, sender},
                  apptReminderNotification: {
                    appt: payload,
                    sender,
                    org: schState.organization,
                    location,
                  },
                  socket: {
                    rooms: socketRooms,
                    arguments: [
                      "new_appt",
                      payload,
                      {ntf, updatePatient: {did: [...pt.did, doctor.did]}},
                    ],
                  },
                })
                  .then((res) => {
                    socket?.current.emit?.(
                      "update_pt",
                      payload.pid,
                      {...pt, did: [...pt.did, doctor.did]},
                      {newDoctor: payload.did},
                      [payload.did, payload.lid + payload.oid, payload.pid]
                    );
                    sendInvEmail(schState, transaction);
                    updateInsuredSessions();
                    dispatch({
                      type: "NEW_APPOINTMENT",
                      payload,
                      updatePatient: {did: [...pt.did, doctor.did]},
                    });
                    stateChanger(false);

                    toast({
                      title: "The appointment has been successfully booked!",
                      status: "success",
                      duration: 3000,
                      isClosable: true,
                    });
                    setCreating(false);
                    clickRef.current = true;
                  })
                  .catch(function (error) {
                    console.log(error);
                    setCreating(false);
                  });
              } else {
                setCreating(false);
                clickRef.current = true;
              }
            });
          } else {
            if (pt.did.length === 0) {
              requestObjs.push({
                db: schState.db,
                collection: "patients",
                parameters: [{pid: pt.pid}, {$set: {did: [doctor.did]}}],
                method: "updateOne",
              });
              socket?.current.emit?.(
                "update_pt",
                payload.pid,
                {...pt, did: [...pt.did, doctor.did]},
                {newDoctor: payload.did},
                [payload.did, payload.lid + payload.oid, payload.pid]
              );
            }

            crud(schState, requestObjs, {
              email: emailOption,
              sms: smsOption,
              // verifyCard: {pid: payload.pid, db: schState.db},
              apptReminderNotification: {
                appt: payload,
                sender,
                org: schState.organization,
                location,
              },
              socket: {
                rooms: socketRooms,
                arguments: [
                  "new_appt",
                  payload,
                  {
                    ntf,
                    updatePatient: pt.did.length === 0 && {did: [doctor.did]},
                  },
                ],
              },
            }).then((res) => {
              // sendInvoiceEmail && sendInvEmail(schState, transaction);
              sendInvEmail(schState, transaction);
              updateInsuredSessions();
              dispatch({
                type: "NEW_APPOINTMENT",
                payload,
                updatePatient: pt.did.length === 0 && {did: [doctor.did]},
              });

              stateChanger(false);
              toast({
                title: "The appointment has been successfully booked!",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              setCreating(false);
              clickRef.current = true;
            });
          }
        }
        if (availableInMembership) {
          const serviceInfo =
            availableInMembership.selectedServices[service.serviceId];
          if (
            serviceInfo &&
            serviceInfo.quantity -
              (serviceInfo.consumed || 0) -
              (serviceInfo.reserved || 0) >
              0
          ) {
            const membershipsServices = {
              ...availableInMembership.selectedServices,
              [service.serviceId]: {
                ...availableInMembership.selectedServices[service.serviceId],
                reserved:
                  (availableInMembership.selectedServices[service.serviceId]
                    .reserved || 0) + 1,
              },
            };
            crud(
              props.schState,
              [
                {
                  db: props.schState.db,
                  collection: "patients",
                  parameters: [
                    {pid: patient.pid},
                    {
                      $set: {
                        "memberships.$[membership].selectedServices":
                          membershipsServices,
                      },
                    },
                    {
                      arrayFilters: [
                        {"membership.uuid": availableInMembership.uuid},
                      ],
                    },
                  ],
                  method: "updateOne",
                },
              ],
              null
            );
            const updatedMemberships = (patient.memberships || []).map(
              (mData) => {
                if (mData.uuid === availableInMembership.uuid) {
                  return {
                    ...mData,
                    selectedServices: membershipsServices,
                  };
                }
                return mData;
              }
            );
            dispatch({
              type: "UPDATE_PATIENT",
              pid: patient.pid,
              payload: {
                memberships: updatedMemberships,
                firstTimeAppt: false,
              },
            });
            return true;
          }
        } else {
          dispatch({
            type: "UPDATE_PATIENT",
            pid: patient?.pid,
            payload: {firstTimeAppt: false},
          });
        }
      };

      if (appointmentAvailable(appointmentsIntervals.current, payload)) {
        let u = (doctor.unavailable.untilRemoved || []).map(([start, end]) => {
          return [
            setToCurrentWeek(new Date(payload.ISOdate), start),
            setToCurrentWeek(new Date(payload.ISOdate), end),
          ];
        });
        if (
          !appointmentAvailable(unavailableDoctorIntervals.current, payload) ||
          !appointmentAvailable(u, payload)
        )
          swal({
            title: `This appointment falls at a time that is not available for ${payload.dName}. Are you sure you want to schedule it for that time?`,
            text: `This appointment will be rescheduled for ${payload.date} at ${payload.time} in your local time.`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((value) => {
            if (value) {
              setCreating(true);
              clickRef.current = false;
              action();
            }
          });
        else {
          setCreating(true);
          clickRef.current = false;
          action();
        }
      } else {
        setErrorMessage("The times for this appointment are already taken.");
        setCreating(false);
        clickRef.current = true;
        bookError.current && (bookError.current.className = "inline-block");
      }
    }
  }
  let availableMembershipServices = {};
  const availableMemberships = patient?.memberships?.filter((memb) => {
    if (memb.status !== "Paid") return false;
    const apptDate = new Date(selectedDate);
    const startDate = new Date(memb.startDate);
    const endDate = new Date(memb.endDate);
    if (apptDate >= startDate && apptDate <= endDate) {
      availableMembershipServices = {
        ...availableMembershipServices,
        ...memb.selectedServices,
      };
    }
    return apptDate >= startDate && apptDate <= endDate;
  });

  return (
    <>
      {showSaleModal && (
        <ExternalSales
          {...{
            state: schState,
            products,
            closeSalesModal: () => setShowSaleModal(false),
            productSalesInformation,
            callback: (productData) => {
              setProductSalesInformation(productData);
            },
          }}
        />
      )}
      <div
        className="fixed inset-0 show z-[999] overflow-hidden flex justify-center items-center"
        style={{backgroundColor: "rgb(0,0,0,0.5)"}}
      >
        <div className="relative flex max-h-[800px] h-[90vh] items-center p-2 py-4 bg-white overflow-y-hidden rounded-3xl  sbar2 justify-center w-[700px]">
          <div className="relative h-full w-full overflow-y-scroll rounded-3xl p-5 sbar">
            <div
              className="relative inline-block w-full  px-4 pt-4 pb-4 overflow-hidden text-left align-bottom transition-all transform rounded-lg shadow-xl sm:my-8  sm:p-6 sm:align-middle"
              style={{backgroundColor: "#F6F5FF"}}
            >
              {errorMessage && (
                <div>
                  <div className="mb-5 flex w-full max-w-lg overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                    <div className="flex items-center justify-center w-12 bg-red-500">
                      <svg
                        className="w-6 h-6 mx-2 text-white fill-current"
                        viewBox="0 0 40 40"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                      </svg>
                    </div>
                    <div className="px-4 py-2 -mx-3">
                      <div className="mx-3">
                        <span className="font-semibold text-red-500 dark:text-red-400">
                          Error
                        </span>
                        <p className="text-sm text-gray-600 dark:text-gray-200">
                          {errorMessage}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="hidden" ref={bookSuccess}>
                <div className="mb-5 flex w-full max-w-lg overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                  <div className="flex items-center justify-center w-12 bg-green-500">
                    <svg
                      className="w-6 h-6 mx-2 text-white fill-current"
                      viewBox="0 0 40 40"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                    </svg>
                  </div>
                  <div className="px-4 py-2 -mx-3">
                    <div className="mx-3">
                      <span className="font-semibold text-green-500 dark:text-green-400">
                        Appointment Booked
                      </span>
                      <p className="text-sm text-gray-600 dark:text-gray-200">
                        Appointment with the below specified logistics has been
                        successfully booked.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex relative">
                <h3
                  className="text-3xl font-medium text-gray-800 m-4 mb-6"
                  id="modal-title"
                  style={{color: "#C0BFFF"}}
                >
                  Book Appointment
                </h3>
                <div className="pl-5">
                  <button
                    type="button"
                    className="absolute -top-2 -right-2 text-gray-400 bg-transparent rounded-lg text-sm  ml-auto inline-flex items-center"
                    data-modal-toggle="small-modal"
                    style={{boxShadow: "none"}}
                    onClick={() => stateChanger(false)}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-4 h-4"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>

              <form className="" action="#">
                <div className="flex justify-between pb-5 border-b border-gray-200">
                  <div className="">
                    <AssignedProfessional
                      {...{assignedProfessional, setAssignedProfessional}}
                    />
                    {assignedProfessional["clinician"] ? (
                      <Selections
                        {...{schState}}
                        currentDoctor={schState.selectedDoctor}
                        role="Doctor"
                        onSelect={setDoctor}
                        defaultDoctor={defaultData?.doctor}
                      />
                    ) : (
                      <label className="block mx-3.5 mt-1" htmlFor="doctor">
                        <span className="text-[11px] text-off ml-2">
                          {`Define Technician`}
                        </span>

                        <input
                          onChange={(e) => {
                            setTechnician(e.target.value);
                          }}
                          value={technician}
                          type="text"
                          className="drop-shadow block w-[17rem] whitespace-nowrap overflow-hidden px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                        />
                      </label>
                    )}
                    <Selections
                      {...{schState}}
                      currentDoctor={schState.selectedDoctor}
                      role="Supervisor"
                      onSelect={(e) => (supervisorRef.current = e)}
                      defaultDoctor={defaultData?.doctor}
                      doctor={doctor}
                    />
                    <PatientContext.Provider value={[patient, setPatient]}>
                      <Selections
                        {...{
                          schState,
                          currentPatient:
                            currentPatient || schState.selectedPatient,
                        }}
                        role="Patient"
                        onSelect={setPatient}
                        patientData={patient}
                      />
                    </PatientContext.Provider>
                    {patient && (
                      <Details
                        width={"17rem"}
                        details={[
                          {
                            TPP: patientTppInfo
                              ? insurersMap[patientTppInfo.insurerId]
                              : "N/A",
                          },
                          {
                            "Insured sessions": patientTppInfo
                              ? patientTppInfo.noOfInsuredSessions ?? "N/A"
                              : "N/A",
                          },

                          {Email: patient.email || "N/A"},
                          {Phone: patient.phone || "N/A"},
                          {Age: patient.dob ? getAge(patient.dob) : "N/A"},
                          {Gender: patient.gender || "N/A"},
                          {
                            "Health Card":
                              patient?.healthCard?.healthCardNo || "N/A",
                          },

                          {
                            Memberships: availableMemberships?.length
                              ? availableMemberships
                                  .map((memb) => memb.name)
                                  .join(", ")
                              : "N/A",
                          },
                        ]}
                      />
                    )}
                    {parseInt(patientTppInfo?.noOfInsuredSessions ?? -1) ===
                      0 && (
                      <div className="mx-3.5">
                        <span className="ml-2 text-yellow-500 text-xs space-x-1 flex items-center">
                          <PiWarningBold />{" "}
                          <span>
                            All sessions insured by this TPP have been used up!
                          </span>
                        </span>
                      </div>
                    )}

                    <label
                      className="block relative mx-3.5 mt-1"
                      htmlFor="doctor"
                    >
                      <span className="text-[11px] text-off ml-2">Note</span>
                      <span
                        className="absolute z-10 top-8 left-3 text-gray-400 text-xs"
                        ref={searchRef}
                        onClick={() => {
                          searchRef.current.className = "hidden";
                          bookNoteRef.current.focus();
                        }}
                      >
                        Write note...
                      </span>
                      <textarea
                        ref={bookNoteRef}
                        onFocus={() => {
                          searchRef.current.className = "hidden";
                        }}
                        onBlur={() => {
                          if (!bookNoteRef.current.value) {
                            setTimeout(() => {
                              if (searchRef.current)
                                searchRef.current.className =
                                  "absolute z-10 top-8 left-3 text-gray-400 text-xs";
                            }, 100);
                          }
                        }}
                        className="h-[4.5rem] drop-shadow block w-[17rem] px-3 py-2.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      />
                    </label>
                    <label className="block mx-3.5 mt-1" htmlFor="type">
                      <span className="text-[11px] text-off ml-2">
                        Select Type
                      </span>
                      <select
                        onChange={(e) => {
                          if (e.target.value === "live")
                            setAddress(location?.address || "");
                          else setAddress(null);
                          setAppointmentType(e.target.value);
                        }}
                        value={appointmentType}
                        type="type"
                        name="type"
                        id="type"
                        className="drop-shadow m-0 block w-[17rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      >
                        <option value="teleconference">🖥 &nbsp;Virtual</option>
                        <option value="live">🎙 &nbsp;In Person</option>
                        <option value="telephone">📞 &nbsp;Telephone</option>
                      </select>
                    </label>
                    {appointmentType === "live" ? (
                      <Address {...{location, address, setAddress}} />
                    ) : null}
                  </div>
                  <div className="flex-1 flex flex-col items-end">
                    <label className="block mx-1 mb-1" htmlFor="service">
                      <span className="text-[11px] text-off ml-2">
                        Select Service
                      </span>
                      <select
                        onChange={(e) => {
                          setSelectedServiceIndx(e.target.value);
                          setDuration(
                            services[e.target.value]?.defaultDuration ||
                              "15 min"
                          );
                        }}
                        ref={bookServiceRef}
                        value={selectdServiceIndx}
                        type="service"
                        name="service"
                        id="service"
                        className="drop-shadow m-0 block w-[10rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      >
                        <option value="">-</option>
                        {services.map((p, i) => {
                          return (
                            <option key={i} value={i}>
                              {p.serviceName}
                            </option>
                          );
                        })}
                      </select>
                    </label>
                    {service && (
                      <Details
                        width={"10rem"}
                        margin={"0 3px"}
                        details={[
                          {Category: service.category || "-"},
                          {"Default Cost/hrs": service.defaultCost || "-"},
                          {"Default Duration": service.defaultDuration || "-"},
                          {
                            "In membership": availableMembershipServices[
                              service?.serviceId
                            ]
                              ? parseInt(
                                  availableMembershipServices[
                                    service?.serviceId
                                  ].quantity
                                ) -
                                  (parseInt(
                                    availableMembershipServices[
                                      service?.serviceId
                                    ].reserved
                                  ) || 0) -
                                  (parseInt(
                                    availableMembershipServices[
                                      service?.serviceId
                                    ].consumed
                                  ) || 0) >
                                0
                                ? parseInt(
                                    availableMembershipServices[
                                      service?.serviceId
                                    ].quantity
                                  ) -
                                  parseInt(
                                    availableMembershipServices[
                                      service?.serviceId
                                    ].reserved || 0
                                  ) -
                                  parseInt(
                                    availableMembershipServices[
                                      service?.serviceId
                                    ].consumed || 0
                                  )
                                : "No"
                              : "No",
                          },
                        ]}
                      />
                    )}

                    <label className="block mx-1 mt-1" htmlFor="service">
                      <span className="text-[11px] text-off ml-2">
                        Select Room
                      </span>
                      <select
                        onChange={(e) => {
                          setSelectedRoom(e.target.value);
                        }}
                        value={selectedRoom}
                        type="room"
                        name="room"
                        id="room"
                        className="drop-shadow m-0 block w-[10rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      >
                        <option value="">-</option>
                        {rooms.map((r, i) => {
                          return (
                            <option key={i} value={r.rid}>
                              {r.roomName}
                            </option>
                          );
                        })}
                      </select>
                    </label>

                    <label className="block mx-1 mt-1" htmlFor="date">
                      <span className="text-[11px] text-off ml-2">
                        Select Date
                      </span>
                      <input
                        ref={bookDateRef}
                        type="date"
                        name="date"
                        id="date"
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                        className="drop-shadow block w-[10rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      />
                    </label>
                    <label className="block mx-1 mt-1" htmlFor="time">
                      <span className="text-[11px] text-off ml-2">
                        Select Time
                      </span>
                      <input
                        ref={bookTimeRef}
                        type="time"
                        name="time"
                        id="time"
                        defaultValue={
                          defaultData
                            ? lightFormat(defaultData.date, "HH:mm")
                            : lightFormat(new Date(), "HH:mm")
                        }
                        className="drop-shadow block w-[10rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                      />
                    </label>

                    <label className="block mx-1 mt-1" htmlFor="duration">
                      <span className="text-[11px] text-off ml-2">
                        {customDuration
                          ? "Define Duration (min)"
                          : "Select Duration"}
                      </span>
                      {customDuration ? (
                        <input
                          onClick={(e) => {}}
                          className="drop-shadow block w-[10rem]  px-4 py-3 pr-8 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                          type="text"
                          value={duration.split(" ")[0]}
                          onBlur={() => {
                            if (!Number(duration.split(" ")[0])) {
                              setDuration(`15 min`);
                            }
                          }}
                          onChange={(e) => {
                            if (
                              !isNaN(e.target.value) &&
                              Number(e.target.value) <= 480
                            ) {
                              let v = e.target.value
                                .replace(/[-+., ]/g, "")
                                .slice(0, 10);
                              setDuration(`${v} min`);
                            }
                          }}
                        />
                      ) : (
                        <select
                          onChange={(e) => {
                            setDuration(e.target.value);
                          }}
                          value={duration || "15 min"}
                          type="duration"
                          name="duration"
                          id="duration"
                          className="drop-shadow block w-[10rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                        >
                          <option value="15 min">15 minutes</option>
                          <option value="30 min">30 minutes</option>
                          <option value="45 min">45 minutes</option>
                          <option value="60 min">60 minutes</option>
                          <option value="75 min">75 minutes</option>
                          <option value="90 min">90 minutes</option>
                          <option value="120 min">120 minutes</option>
                        </select>
                      )}
                    </label>
                    {/*<label className="block mx-1 mt-1  w-[10rem]">
                    <span className="text-[11px] text-off ml-2">
                      Auto-email invoice
                    </span>
                    <AutoEmailInvoice
                      {...{sendInvoiceEmail, setSendInvoiceEmail}}
                    />
                  </label>*/}
                    <FormControl
                      mt="1.5"
                      display="flex"
                      alignItems="center"
                      pl="1.5"
                      w="10rem"
                    >
                      <FormLabel
                        cursor={"pointer"}
                        fontSize={"11px"}
                        htmlFor="fixed-cost"
                        mb="0"
                        fontWeight={"normal"}
                        color={customDuration ? "#5754FF" : "#7F7B91"}
                      >
                        Custom Duration
                      </FormLabel>
                      <Switch
                        isChecked={customDuration}
                        onChange={(e) => {
                          setCustomDuration((prev) => !prev);
                        }}
                        size="sm"
                        id="fixed-cost"
                      />
                    </FormControl>
                    <div className="block relative pl-2  mt-4  w-[10rem] ">
                      <span className="text-[11px] text-off ">
                        Confirmation via
                      </span>
                      <div className="flex  flex-col justify-start">
                        <FormControl
                          mt="1.5"
                          display="flex"
                          alignItems="center"
                          pl="1.5"
                        >
                          <FormLabel
                            mr="1"
                            mb="0"
                            cursor={"pointer"}
                            fontSize={"11px"}
                            htmlFor="via-email"
                            fontWeight={"normal"}
                            w={"30px"}
                            color={
                              confirmationMeans.email ? "#5754FF" : "#7F7B91"
                            }
                          >
                            Email
                          </FormLabel>
                          <Switch
                            isChecked={confirmationMeans.email}
                            onChange={(e) => {
                              setConfirmationMeans((prev) => ({
                                ...prev,
                                email: !prev.email,
                              }));
                            }}
                            size="sm"
                            id="via-email"
                          />
                        </FormControl>
                        <FormControl
                          mt="1.5"
                          display="flex"
                          alignItems="center"
                          pl="1.5"
                        >
                          <FormLabel
                            mr="1"
                            mb="0"
                            w={"30px"}
                            cursor={"pointer"}
                            fontSize={"11px"}
                            htmlFor="via-text"
                            fontWeight={"normal"}
                            color={
                              confirmationMeans.text ? "#5754FF" : "#7F7B91"
                            }
                          >
                            Text
                          </FormLabel>
                          <Switch
                            isChecked={confirmationMeans.text}
                            onChange={(e) => {
                              setConfirmationMeans((prev) => ({
                                ...prev,
                                text: !prev.text,
                              }));
                            }}
                            size="sm"
                            id="via-text"
                          />
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>

                {
                  <Recurrent
                    {...{
                      selectedDate,
                      setSelectedDate,
                      recurrentCriteriaRef,
                      setRecurrent,
                      recurrent,
                    }}
                  />
                }

                <NotifyBefore notifySelectionsRef={notifySelectionsRef} />
                <DefineApptServiceCriteria
                  {...{
                    service: services[selectdServiceIndx],
                    criteria,
                    initialCriteria,
                    criteriaKeyRef,
                    creating,
                    action: (crt) => {
                      setServiceCriteriaKey(criteriaFormatKey(crt));
                    },
                  }}
                />
                <SelectedProducts
                  {...{
                    productSalesInformation,
                    setProductSalesInformation,
                  }}
                />
                <div className="flex">
                  <Button
                    leftIcon={<TiShoppingCart />}
                    flex={1}
                    type="submit"
                    form="buyerInfo"
                    w="40"
                    colorScheme="blue"
                    mr={3}
                    cursor={creating ? "not-allowed" : "pointer"}
                    onClick={async () => {
                      if (!creating) setShowSaleModal(true);
                    }}
                  >
                    {productSalesInformation?.products.length > 0
                      ? "Edit Products"
                      : "Add Products"}
                  </Button>

                  <button
                    type="button"
                    className="w-full flex-1 text-white py-2 bg-med/80 hover:bg-med rounded-lg font-medium flex justify-center items-center"
                    onClick={() => {
                      if (clickRef.current)
                        bookAppointment(availableMemberships);
                    }}
                    disabled={creating}
                  >
                    Book Appointment
                    {creating && (
                      <span className="">
                        <svg
                          className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25 stroke-[4px]"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Selections(options) {
  const p = useContext(PatientContext);
  const [value, setValue] = useState("");
  const [showSearchList, setShowSearchList] = useState(true);
  const [showOptionsList, setShowOptionsList] = useState(false);
  const schState = options.schState;
  const currentPatient = options.currentPatient;
  const currentDoctor = options.currentDoctor;
  const role = options.role;
  const selectedDoctor = options.doctor;
  const clickRef = useRef(false);
  const [doctors, setDoctors] = useState(schState.doctors || []);
  const [prevDoctor, setPrevDoctorDoctors] = useState(selectedDoctor);

  if (selectedDoctor !== prevDoctor && role === "Supervisor") {
    let sup = doctors.find((d) => d.did === selectedDoctor?.supervisor);
    setPrevDoctorDoctors(selectedDoctor);
    if (sup) {
      setValue(`${sup?.lastName}, ${sup?.firstName}`);
      options.onSelect(sup);
    }
  }

  useEffect(() => {
    let arr = [];

    let f = (a) => {
      if (role === "Supervisor" && selectedDoctor) {
        let sup = a?.find((d) => d.did === selectedDoctor?.supervisor);
        if (sup) {
          setValue(`${sup?.lastName}, ${sup?.firstName}`);
          options.onSelect(sup);
        } else {
          setValue(`${sup?.lastName}, ${sup?.firstName}`);
          options.onSelect(selectedDoctor);
        }
      }
    };

    if (schState.userType === "doctor") {
      crud(schState, [
        {
          db: schState.db,
          collection: "doctors",
          parameters: [{}],
          method: "find",
        },
      ]).then((res) => {
        arr = res.data[0];
        setDoctors(res.data[0]);
        f(arr);
      });
    } else {
      f(schState.doctors);
    }

    if (options.defaultDoctor && role === "Doctor") {
      setValue(
        `${options.defaultDoctor.lastName}, ${options.defaultDoctor.firstName}`
      );
    }
  }, []);

  useEffect(() => {
    const handler = () => {
      setShowSearchList((e) => e && !e);
      if (!clickRef.current) {
        setShowOptionsList((e) => e && !e);
      }

      clickRef.current = false;
    };
    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  }, []);

  return (
    <label className="block mx-3.5 mt-1" htmlFor="doctor">
      <span className="text-[11px] text-off ml-2">
        {`${currentPatient || currentDoctor ? "Selected" : "Select"}  ${
          role == "Doctor" ? "Clinician" : role
        }`}
      </span>
      {(!schState.selectedDoctor && role === "Doctor") ||
      (!currentPatient && role === "Patient") ||
      role === "Supervisor" ? (
        <div className="relative flex justify-center items-center">
          {showSearchList && (
            <SearchList
              onSelect={(e) => {
                setValue(
                  role === "Doctor" || role === "Supervisor"
                    ? e.lastName + ", " + e.firstName
                    : e.lName + ", " + e.fName
                );
                options.onSelect(e);
              }}
              {...{schState, doctors, role}}
              value={value}
              fields={
                role === "Doctor" || role === "Supervisor"
                  ? ["email", "name", "memberships"]
                  : [
                      "fName",
                      "lName",
                      "email",
                      "healthCard",
                      "phone",
                      "memberships",
                    ]
              }
            />
          )}

          {showOptionsList && (
            <OptionsList
              {...{schState, role, doctors, showOptionsList}}
              onSelect={(e) => {
                setValue(
                  role === "Doctor" || role === "Supervisor"
                    ? e.lastName + ", " + e.firstName
                    : e.lName + ", " + e.fName
                );
                options.onSelect(e);
              }}
            />
          )}

          <input
            onClick={(e) => {
              setShowSearchList(true);
              setShowOptionsList(false);
            }}
            className="drop-shadow block w-[17rem]  px-4 py-3 pr-8 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            type="text"
            value={value}
            onChange={(e) => {
              setValue(e.target.value.trim());
              setShowOptionsList(false);
              setShowSearchList(true);
            }}
          />
          <span
            className="absolute right-2.5  h-full flex justify-center items-center hover:cursor-pointer"
            onClick={(e) => {
              //e.stopPropagation();
              clickRef.current = true;
              setShowSearchList(false);
              setShowOptionsList((s) => !s);
            }}
          >
            <PiArrowCircleDownFill
              className="h-5 w-5 text-off"
              aria-hidden="true"
            />
          </span>
        </div>
      ) : (
        <input
          type="text"
          className="drop-shadow block w-[17rem] whitespace-nowrap overflow-hidden px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          readOnly={true}
          defaultValue={
            currentPatient
              ? currentPatient.lName +
                ", " +
                currentPatient.fName +
                " (" +
                currentPatient.email +
                ")"
              : `${currentDoctor.lastName}, ${currentDoctor.firstName}`
          }
        />
      )}
    </label>
  );
}

function searchMatch(_data = [], entri, fields) {
  if (entri === "") return [];
  let sanitizedEntri = entri.replace(/[$^.*+?=!:|/()[\]{}]/g, (e) => "\\" + e);
  let regex = new RegExp(`${sanitizedEntri}`, "i");
  let d = _data.filter((e, i) => {
    for (let field of fields) {
      if (typeof e[field] === "string" && e[field]?.match(regex)) return true;
    }
    return false;
  });
  return d;
}

function OptionsList({onSelect, schState, role, doctors}) {
  let {doctorsPayload} = schState;
  return (
    <>
      {(role === "Doctor" || role === "Supervisor") && doctors ? (
        <div className="max-h-[5rem] flex pr-1 flex-col overflow-hidden w-full absolute top-full left-0 z-10 bg-white drop-shadow border border-gray-200 rounded-xl">
          <ul className="flex-1 overflow-auto sbar2 overflow-x-hidden text-sm text-off font-medium flex flex-col">
            {doctors.map((e, i) => {
              return (
                <li
                  className="hover:bg-indigo-500/60 hover:text-white hover:cursor-pointer px-4 "
                  key={i}
                  onClick={() => onSelect(e)}
                >
                  {`${e.lastName}, ${e.firstName}`}
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <div className="max-h-[8rem] flex pr-1 flex-col overflow-hidden w-full absolute top-full left-0 z-20 bg-white drop-shadow border border-gray-200 rounded-xl">
          <ul className="flex-1 overflow-auto sbar2 text-sm text-off font-medium flex flex-col overflow-x-hidden">
            {schState.userType === "doctor" ? (
              schState.patients.map((pat, i) => {
                return (
                  <li
                    className="hover:bg-indigo-500/60 hover:text-white hover:cursor-pointer px-4 whitespace-nowrap"
                    key={i}
                    onClick={
                      /* If user has no access to name, then cant schedule appointment */
                      verifyAccess(schState, "fName")
                        ? () => onSelect(pat)
                        : null
                    }
                  >
                    {renderTextByAccess(schState, pat, "lName", undefined, " ")}
                    {", "}
                    {renderTextByAccess(
                      schState,
                      pat,
                      "fName",
                      undefined,
                      " "
                    )}{" "}
                  </li>
                );
              })
            ) : (
              <>
                {doctors
                  .filter((ele) => doctorsPayload[ele.did].patients.length > 0)
                  .sort((p1, p2) => {
                    let name1 =
                      `${p1.lastName}, ${p1.firstName}`.toLocaleLowerCase(
                        "en-CA"
                      );
                    let name2 =
                      `${p2.lastName}, ${p2.firstName}`.toLocaleLowerCase(
                        "en-CA"
                      );
                    if (name1 < name2) {
                      return -1;
                    }
                    if (name1 > name2) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((d, i) => (
                    <li className="" key={i}>
                      <div className="text-[#9F9DFA] px-4">{`${d.lastName}, ${d.firstName}`}</div>
                      <ul className="">
                        {doctorsPayload[d.did].patients.map((pat, i) => (
                          <li
                            className="hover:bg-indigo-500/60 hover:text-white hover:cursor-pointer px-8 whitespace-nowrap"
                            key={i}
                            onClick={
                              /* If user has no access to name, then cant schedule appointment */
                              verifyAccess(schState, "fName")
                                ? () => onSelect(pat)
                                : null
                            }
                          >
                            {/* Admin Profile View: Dr-Patient */}
                            {renderTextByAccess(schState, pat, "lName")}
                            {", "}
                            {renderTextByAccess(schState, pat, "fName")}
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                {schState.unassignedPatients.length > 0 && (
                  <li className="">
                    <div className="text-indigo-600 px-4">{"Unassigned"}</div>
                    <ul className="">
                      {schState.unassignedPatients.map((pat, i) => (
                        <li
                          className="hover:bg-indigo-500/60 hover:text-white hover:cursor-pointer px-8 whitespace-nowrap"
                          key={i}
                          onClick={
                            /* If user has no access to name, then cant schedule appointment */
                            verifyAccess(schState, "fName")
                              ? () => onSelect(pat)
                              : null
                          }
                        >
                          {/* Admin Profile View: Unassigned patients */}
                          {renderTextByAccess(schState, pat, "lName")}
                          {", "}
                          {renderTextByAccess(schState, pat, "fName")}
                        </li>
                      ))}
                    </ul>
                  </li>
                )}
              </>
            )}
          </ul>
        </div>
      )}
    </>
  );
}

function SearchList({schState, value, fields, role, onSelect, doctors}) {
  const [searchArr, setSearchArr] = useState([]);

  useEffect(() => {
    let search = () => {
      let a = searchMatch(
        role === "Doctor" || role === "Supervisor"
          ? schState.doctors || doctors
          : schState.patients,
        value,
        fields
      );
      setSearchArr(a);
    };
    let id = setTimeout(search, 200);
    return () => {
      clearTimeout(id);
    };
  }, [value]);

  return (
    <>
      {searchArr.length !== 0 ? (
        <div className="max-h-[5rem] flex pr-1 flex-col overflow-hidden w-full absolute top-full left-0 z-[100] bg-white drop-shadow border border-gray-200 rounded-xl">
          <ul className="flex-1 overflow-auto sbar2 text-sm text-off font-medium flex flex-col">
            {searchArr.map((e, i) => (
              <li
                className="hover:bg-indigo-500/60 hover:text-white hover:cursor-pointer px-4"
                key={i}
                onClick={() => onSelect(e)}
              >
                {role === "Doctor" || role === "Supervisor"
                  ? `${e.name}`
                  : e.lName + " " + e.fName}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </>
  );
}

function NotifyBefore({notifySelectionsRef}) {
  const [selections, setSelections] = useState(new Set([1, 24]));

  return (
    <div className="flex flex-col items-start space-y-2 mx-4 mt-3">
      <p className="text-off font-normal text-sm text-start">
        Remind the patient of this appointment before
      </p>
      <div className="flex space-x-10">
        {[1, 2, 6, 12, 24, 48, 72, 168].map((d, i) => (
          <div
            key={i}
            className="flex flex-col justify-center items-center space-y-3"
          >
            <div
              className=" h-7 w-7 rounded-md shadow02 bg-white hover:cursor-pointer"
              style={{
                backgroundColor: selections.has(d) ? "#9F9DFA" : null,
              }}
              onClick={() => {
                !selections.has(d)
                  ? setSelections((prev) => {
                      prev.add(d);
                      let n = new Set(prev);
                      notifySelectionsRef.current = n;
                      return n;
                    })
                  : setSelections((prev) => {
                      if (prev.size === 1) return prev;
                      prev.delete(d);
                      let n = new Set(prev);
                      notifySelectionsRef.current = n;
                      return n;
                    });
              }}
            ></div>
            <p className="text-slate-400 text-sm">
              {d === 168 ? "week" : `${d}/h`}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

function BookingMedia({setBookingMedia, bookingMedia}) {
  let entries = Object.entries(bookingMedia);

  return (
    <div className="flex flex-col items-start space-y-2 mx-4 my-7">
      <p className="text-off font-normal text-sm text-start">Booking Media</p>
      <div className="flex space-x-5">
        {entries.map(([key, value], i) => (
          <div
            key={i}
            className="flex flex-col justify-center items-center space-y-2"
          >
            <div
              className=" h-7 w-7 rounded-md shadow02 bg-white hover:cursor-pointer"
              style={{
                backgroundColor: value ? "#9F9DFA" : null,
              }}
              onClick={() => {
                setBookingMedia((prev) => {
                  let n = Object.entries(prev).map(([k, v]) => [k, k == key]);
                  return Object.fromEntries(n);
                });
              }}
            ></div>
            <p className="text-slate-400 text-sm">{key}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

function FirstTime({firstTime, setFirstTime, patient}) {
  return (
    <div className="flex flex-col items-start space-y-2 mx-4 my-7">
      <p className="text-off font-normal text-sm text-start">First Time</p>
      <div className="flex space-x-5">
        <div className="flex flex-col justify-center items-center space-y-2">
          <div
            className=" h-7 w-7 rounded-md shadow02 bg-white"
            style={{
              backgroundColor:
                patient?.firstTimeAppt === false ? null : "#9F9DFA",
            }}
            onClick={() => {
              //setFirstTime((prev) => !prev);
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}

function AutoEmailInvoice({sendInvoiceEmail, setSendInvoiceEmail}) {
  return (
    <div
      className="bg-[#9F9DFA] ml-2 rounded-full p-2 w-20 cursor-pointer transition duration-500"
      onClick={() => {
        setSendInvoiceEmail((prev) => !prev);
      }}
      style={{
        backgroundColor: sendInvoiceEmail ? "#9F9DFA" : "#FFFFFF",
        boxShadow: !sendInvoiceEmail && "0 0 0 2px inset #9F9DFA",
      }}
    >
      <p
        className="w-3 h-3 rounded-full bg-white transition duration-500"
        style={{
          transform: sendInvoiceEmail ? "translateX(55px)" : "translateX(0)",
          backgroundColor: sendInvoiceEmail ? "#FFFFFF" : "#9F9DFA",
        }}
      ></p>
    </div>
  );
}

function Details({details, width, margin}) {
  const boxRef = useRef();

  return (
    <div
      ref={boxRef}
      className="anim_height p-2 block mx-3.5 mt-1 shadow-inner whitespace-nowrap overflow-hidden  text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl "
      style={{width: width || "100%", margin: margin || null}}
    >
      <ul className="flex-1 overflow-scroll h-full sbar2 overflow-x-auto  text-off font-medium flex flex-col">
        {details.map((e, i) => {
          let [key, value] = Object.entries(e)[0];
          return (
            <li className="text-xs" key={i}>
              <Text>
                <span className="text-[#9F9DFA] font-medium">{`${key}: `}</span>
                {value}
              </Text>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function Address({location, address, setAddress}) {
  let additionalAddress = [location?.address || ""].concat(
    location?.additionalAddress || []
  );

  return (
    <div className="anim_height  block w-[17rem] mx-3.5 mt-2  whitespace-nowrap  text-sm text-off font-medium ">
      <label className="block " htmlFor="type">
        <span className="text-[11px] text-off ml-2">Select Address</span>
        <select
          onChange={(e) => {
            setAddress(e.target.value);
          }}
          value={address}
          type="address"
          id="address"
          className="drop-shadow m-0 block w-[17rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
        >
          {additionalAddress.map((e, i) => (
            <option key={i} value={e}>
              {e}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
}

function getAge(dateString) {
  try {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getUTCFullYear() - birthDate.getUTCFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  } catch (error) {
    return "-";
  }
}
function id(n) {
  return Array.from({length: n})
    .map(() => `${Math.round(Math.random() * 9)}`)
    .join("");
}
