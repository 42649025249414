import {useReducer, useState, useEffect, createContext} from "react";
import {useLocation} from "react-router-dom";
import {scheduleReducer, init} from "../additional_files/reducer";
import {verifyUser} from "../authRequests";
import Navigation from "../components/Navigation";
import PatientsTable from "../components/Patients/PatientsTable";
import Patient from "../components/Patients/IndividualPatient";
import {useNavigate} from "react-router-dom";
export const DashStateContext = createContext();
export const PatientContext = createContext();
export const DispatchContext = createContext();

export default function Patients(props) {
  const {state} = useLocation();
  const navigate = useNavigate();

  const [dashState, dispatch] = useReducer(scheduleReducer, state, init);
  const [patient, setPatient] = useState(() => {
    let patients = dashState.patients || [];
    if (dashState.selectedPid) {
      let pt = patients.find((p) => p.pid === dashState.selectedPid);
      dashState.selectedPid = "";
      return pt;
    }
    return null;
  });

  const [prevState, setPrevstate] = useState(state);
  const [patientQueryParams, setPatientQueryParams] = useState(() => {
    let {patientChartAid, patientChartTab} = dashState;
    dashState.patientChartAid = "";
    dashState.patientChartTab = "";
    return {aid: patientChartAid, tab: patientChartTab};
  });
  useEffect(function () {
    verifyUser(state ? state.jwt : "");
  }, []);

  if (prevState !== state) {
    setPrevstate(state);
    dispatch({type: "UPDATE_STATE", payload: state});
  }

  return (
    <Navigation
      state={dashState}
      patient={patient}
      setPatient={setPatient}
      dispatch={dispatch}
    >
      {dashState.changingLocation && (
        <div className="fixed  inset-0 bg-white/80 z-[99999]  flex justify-center items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-4 border-dark"></div>
        </div>
      )}
      {dashState && (
        <DashStateContext.Provider value={dashState}>
          <DispatchContext.Provider value={dispatch}>
            {!patient ? (
              <PatientsTable
                dispatch={dispatch}
                dashState={dashState}
                setPatient={setPatient}
                navigate={navigate}
                setPatientQueryParams={setPatientQueryParams}
              />
            ) : (
              <PatientContext.Provider value={[patient, setPatient]}>
                <Patient
                  dispatch={dispatch}
                  dashState={dashState}
                  setPatient={setPatient}
                  patientQueryParams={patientQueryParams}
                />
              </PatientContext.Provider>
            )}
          </DispatchContext.Provider>
        </DashStateContext.Provider>
      )}
    </Navigation>
  );
}
