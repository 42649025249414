export const alphabeticalSort = (array, property) => {
  const orderedArray = array
  return orderedArray.sort((a, b) => {
    if (a[property].toLowerCase().trim() < b[property].toLowerCase().trim() ) {
      return -1
    }
    if (a[property].toLowerCase().trim() > b[property].toLowerCase().trim() ) {
      return 1
    }
    return 0
  })
}
